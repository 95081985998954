export const PROBLEMLIST_STATE_INIT = "PROBLEMLIST_STATE_INIT";
export const PROBLEMLIST_STATE_RESET = "PROBLEMLIST_STATE_RESET";
export const PROBLEMLIST_STATE_LOADING = "PROBLEMLIST_STATE_LOADING";
export const PROBLEMLIST_STATE_SUCCESS = "PROBLEMLIST_STATE_SUCCESS";

export const studentProblemSetComponentChangeStateToInit = (problemList) => ({
    type: PROBLEMLIST_STATE_INIT,
    payload: problemList,
});

export const studentProblemSetComponentChangeStateToReset = (element) => ({
    type: PROBLEMLIST_STATE_RESET,
    payload: element,
});

export const studentProblemSetComponentChangeStateToLoading = (problemTemplateListId) => ({
    type: PROBLEMLIST_STATE_LOADING,
    payload: problemTemplateListId,
});

export const studentProblemSetComponentChangeStateToSuccess = (problemList) => ({
    type: PROBLEMLIST_STATE_SUCCESS,
    payload: problemList,
});
