import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import {
  Navbar,
  Nav,
  Button,
  Image,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";

function ProfileNavbar({ handleSaveProfile, navbarTitle }) {
  const { t } = useTranslation();
  
  return (
    <Navbar className="navbar-button-right" fixed="top">
      <Navbar.Brand href="/">
        <Image src={LogoWhite} className="logo" rounded />
      </Navbar.Brand>
      <Navbar.Brand>
        <Container className="nav-container">
          <Row className="nav-container-row">
            <Col>
              <span className="navbar-title-top">Nagy Réka</span>
              <br></br>
              <span className="navbar-title-bottom">{navbarTitle}</span>
            </Col>
          </Row>
        </Container>
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <Button
        bsPrefix="toolbar"
        className="btn navbar-button"
        onClick={() => handleSaveProfile()}
      >
        <ArrowForwardIosIcon className="icon button-icon" /> {t("Navbar.Profile-Navbar.save")}
      </Button>
    </Navbar>
  );
}

export default ProfileNavbar;
