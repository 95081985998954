export const CREATETEMPLATE_STATE_INIT = "CREATETEMPLATE_STATE_INIT";
export const CREATETEMPLATE_STATE_RESET = "CREATETEMPLATE_STATE_RESET";

export const initCreateTemplateState = (element) => ({
  type: CREATETEMPLATE_STATE_INIT,
  payload: element,
});

export const resetCreateTemplateState = (element) => ({
  type: CREATETEMPLATE_STATE_RESET,
  payload: element,
});
