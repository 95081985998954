import axios from "axios";
import { checkRequirement } from "../helpers";
import {
    resetProblemTemplateListSummariesState,
    initProblemTemplateListSummariesState,
} from "../state/problemtemplatelistsummaries/actions";
import { initErrorState } from "../state/error/actions";

export function getProblemTemplateListSummaries(
    cookies,
    dispatch,
    language,
    history,
    onLoadComplete,
    onLoadError
) {
    if (checkRequirement(cookies)) {
        dispatch(resetProblemTemplateListSummariesState());
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/problemtemplatelist/list`, {
                headers: {
                    "X-Authenticated-User": cookies.guid,
                    "Accept-Language": language,
                },
            })
            .then((res) => {
                dispatch(initProblemTemplateListSummariesState(res.data));
                onLoadComplete();
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                onLoadError(err);
                history.push("/error");
            });
    }
}
