import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateProblemList } from "../../api/GenerateProblemList";
import { getSolutionStepOptions } from "../../api/GetSolutionStepOptions";
import { getProblemListState } from "../../state/problemlist/selectors";
import { studentProblemSetComponentChangeStateToSuccess, studentProblemSetComponentChangeStateToReset } from "../../state/problemlist/actions";
import StudentProblemListToSolveComponent from "./StudentProblemListToSolveComponent";
import StudentProblemListEvaluatedComponent from "./StudentProblemListEvaluatedComponent";
import { useLanguageCode } from "../../helpers";

/**
 * StudentJourneyProvider represents the main (article) area of the current page during the problem list solution, typically opened by the student who got a link from the teacher.
  * @returns The react-bootstrap container that represents the body of the current page.
 */
function StudentJourneyProvider({
    changeNavigationBarModeTo,
    onProblemListIdChanged,
    setCanClickStudentSubmit
}) {
    const [value, setValue] = useState(1);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(["guid"]);
    
    const history = useHistory();
    const [userName] = useState();
    const { problemTemplateListId } = useParams();
    const { t, i18n } = useTranslation();

    const problemList = useSelector(getProblemListState);
    const currentState = problemList.problems;
    
    // change the navigation bar to the yellow student version
    changeNavigationBarModeTo("Student");
    setCanClickStudentSubmit(true);

    useEffect(() => {

        var code="en-US"

        if(cookies.lang!==undefined) {
            if(cookies.lang==="en") code="en-US"
            if(cookies.lang==="hu") code="hu-HU"
        } else {
            if(i18n.language==="en") code="en-US";
            if(i18n.language==="hu") code="hu-HU";
        }
    
        if(window.location.pathname === `/${problemTemplateListId}` ) {
            if(code==="hu-HU") window.location.assign(`/hu-HU/${problemTemplateListId}`);
            else window.location.assign(`/en-US/${problemTemplateListId}`);
        } else {
            setLanguage();
        }   

    },[]);

    var problemSolutionStepsToGet = 0;

    const getSolutionStepOptionsOnSuccess = (problem, solutionsStepIndex, solutionStepOptions) => {

        if (solutionStepOptions.length > 0) {
            // we got back valid results
            if (problem.solutionSteps.length - 1 < solutionsStepIndex) {
                problem.solutionSteps.push(new Object());
            }
            problem.solutionSteps[solutionsStepIndex].offeredSolutionStepOptions = solutionStepOptions;
            problem.solutionSteps[solutionsStepIndex].selectedSolutionStepOptionIndex = -1;
        }

        problemSolutionStepsToGet--;

        if (problemSolutionStepsToGet === 0) {
            // we got all the solutions steps we needed for all the problems, so we need an re-rendering of the component                
            studentProblemSetComponentChangeStateToSuccess(problemList);
            setValue(value + 1);
            
        }
    };

    // generate or load the problem list associated with the {problemTemplateListId} ID and {cookies.guid} hash
    const generateProblemListOnSuccess = (problemList) => {
        onProblemListIdChanged(problemList.id);

        if (problemList.id !== null) {
            problemSolutionStepsToGet = problemList.problems.length;
            problemList.problems.forEach((problem, index) => {

                if ((problem.solutionSteps.length > 0) && (problem.solutionSteps[problem.solutionSteps.length - 1].selectedSolutionStepOptionIndex === -1)) {
                    // we already have the solution step options that we need to display, so we don't need to call the backend again
                    problemSolutionStepsToGet--;
                } else {
                    const hu = window.location.href.includes("hu-HU");
                    getSolutionStepOptions(
                        cookies,
                        hu? ("hu-HU"):("en-US"),
                        problem,
                        problem.solutionSteps.length,
                        getSolutionStepOptionsOnSuccess
                    );
                }
            });
        }
    };

    const setLanguage = () => {
        const en = window.location.href.includes("en-US");
        const hu = window.location.href.includes("hu-HU");

        if (en) {

            i18n.changeLanguage("en");
            setCookie("lang", "en", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
            
        } else if(hu) {

            i18n.changeLanguage("hu");
            setCookie("lang", "hu", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
            
        } 

    }

    if (problemList.id === null ) {

        const hu = window.location.href.includes("hu-HU");
        const code=hu? ("hu-HU"):("en-US")

        generateProblemList(
            cookies,
            dispatch,
            history,
            problemTemplateListId,
            cookies.guid+code,
            code,
            generateProblemListOnSuccess
        );
    } 

    if(problemList.id != null){
        const hu = window.location.href.includes("hu-HU");
        const code=hu? ("hu-HU"):("en-US")
        if(problemList.culture!="" && problemList.culture!=code && problemList.culture!=undefined && problemList.culture !=null) {
            problemList.id= null;
        }
    }

    useEffect(()=>{
        setValue(value+1);
    },[problemList]);
    

    return (
        <Container fluid>
            {                
                problemList.evaluationCompletedAt === null ? (
                    // show the problem list that needs to be solved
                    <StudentProblemListToSolveComponent problemList={problemList} state = {currentState} />
                ) : (
                    // show the read-only, evaluated problem list
                    <StudentProblemListEvaluatedComponent problemList={problemList} />
                )
                
            }
        </Container>
    );
}

export default StudentJourneyProvider;
