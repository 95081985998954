import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/exercise.css";
import "../../../styles/create.css";
import { Card, Row, Col, Container, Badge, Spinner } from "react-bootstrap";
import { selectSolutionStepOption } from "../../../api/SelectSolutionStepOption";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLanguageCode } from "../../../helpers";

/**
 * ProblemToSolveComponent is a component that is used during the problem list solution process typically by the student.
 */
function ProblemToSolveComponent({ key, problem, problemListId, index, problemState}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [solutionStepsArray, setSolutionStepsArray] = useState([]);
    const [solutionStepsIndex, setSolutionStepsIndex] = useState(
        problemState.solutionSteps.length === 0 ? 0 : problemState.solutionSteps.length - 1
    );
    const [
        selectedSolutionStepOptionIndexes,
        setSelectedSolutionStepOptionIndexes,
    ] = useState([]);
    const [cookies] = useCookies(["guid"]);
    const { t, i18n } = useTranslation();
    var language = useLanguageCode();

    const spinner = problemState.solutionSteps.length === 0

    useEffect(() => {
        let newSolutionStepsArray = [];
        let newSolutionStepsIndexArray = [];
        problemState.solutionSteps.forEach((solutionStep) => {
            newSolutionStepsArray.push(solutionStep.offeredSolutionStepOptions);
            newSolutionStepsIndexArray.push(
                solutionStep.selectedSolutionStepOptionIndex
            );
        });

        setSolutionStepsArray(newSolutionStepsArray);
        setSelectedSolutionStepOptionIndexes(newSolutionStepsIndexArray);
    },[spinner]);

    useEffect(() => {
        if(window.location.href.includes("en-US")) language = "en-US";
        if(window.location.href.includes("hu-HU")) language = "hu-HU";
    })

    const handleNewSolutionStepOptions = (newSolutionStepOptions, index) => {
        if (solutionStepsArray.length - 1 === index) {
            setSolutionStepsArray([...solutionStepsArray, newSolutionStepOptions]);
            setSelectedSolutionStepOptionIndexes([
                ...selectedSolutionStepOptionIndexes,
                -1,
            ]);
            setSolutionStepsIndex(solutionStepsIndex + 1);
        }
    };

    const handleSelectedStepOption = (newSelectedStepOption, index, sIndex) => {
        let newSelectedSolutionStepOptionIndexes = selectedSolutionStepOptionIndexes;
        newSelectedSolutionStepOptionIndexes[index] = sIndex;
        setSelectedSolutionStepOptionIndexes([
            ...newSelectedSolutionStepOptionIndexes,
        ]);
        selectSolutionStepOption(
            cookies,
            dispatch,
            language,
            history,
            problemState.guid,
            newSelectedStepOption,
            handleNewSolutionStepOptions,
            index
        );
    };

    useEffect(() => {
        let newSelectedSolutionStepOptionIndexes = [];
        problemState.solutionSteps.forEach((solutionSteps) => {
            newSelectedSolutionStepOptionIndexes.push(
                solutionSteps.selectedSolutionStepOptionIndex
            );
        });
        setSelectedSolutionStepOptionIndexes(newSelectedSolutionStepOptionIndexes);
    }, [spinner]);

    return (
        <Card className={`exercise-create-card source-file-ProblemToSolveComponent ${ spinner ? "active-card-spinner " : " "}`}>
            { spinner ? (
                <Spinner
                    animation="border"
                    role="status"
                    variant="primary"
                    className={"mx-auto my-auto"}
                >
                    <span className="sr-only">{t("Global.loading")}</span>
                </Spinner>
            ) : (
                    <Container className="exercise-create-container">
                        <Row className="exercise-create-row">
                            <Col xs={12} md={12}>
                                <Row className="exercise-create-row">
                                    <Col className="exercise-create-normal large-body-text exercise-border">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: problemState.index + 1 + ". " + problemState.question,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="exercise-create-row">
                                    <Col className="exercise-create-normal">
                                        {((solutionStepsArray !== undefined) && (solutionStepsArray.length > 0) ? solutionStepsArray.sort((a, b) => a.index - b.index).map((solutionSteps, index) => {
                                            return (solutionSteps !== undefined ? (
                                                <div>
                                                    <p style={{ display: "none" }}>{index + 1}.</p>
                                                    {solutionSteps.sort((a, b) => a.index - b.index).map((solutionStepOption, sIndex) => {
                                                        return (
                                                            <Badge
                                                                className={`multiple-choise-answer ${solutionStepOption.guid ===
                                                                        (selectedSolutionStepOptionIndexes[index] !==
                                                                            -1 &&
                                                                            selectedSolutionStepOptionIndexes[index] !==
                                                                            undefined
                                                                            ? solutionSteps[
                                                                                selectedSolutionStepOptionIndexes[index]
                                                                            ].guid
                                                                            : -1)
                                                                        ? "multiple-choise-answer-selected"
                                                                        : ""
                                                                    }`}
                                                                key={solutionStepOption.guid}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: solutionStepOption.presentation,
                                                                }}
                                                                onClick={() => {
                                                                    handleSelectedStepOption(
                                                                        solutionStepOption.guid,
                                                                        index,
                                                                        sIndex
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            ) : "");
                                        }) : "")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                )}
        </Card>
    );
}

export default ProblemToSolveComponent;
