import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/group.css";
import { Card, Accordion, Button, Container, Row, Col } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List3Exercise from "../list/List3Exercise";

function ContentGroupExercise({
  title,
  problemTemplateListsNumber,
  problemTemplateLists,
}) {
  const [isOpen, setIsOpen] = useState(0);

  function changeIsOpenState() {
    isOpen === 0 ? setIsOpen(1) : setIsOpen(0);
  }

  return (
    <Accordion
      defaultActiveKey="-1"
      className="content-group-exercise-accordion"
    >
      <Card className={isOpen === 1 ? "content-group-exercise-open" : ""}>
        <Accordion.Toggle
          as={Button}
          onClick={changeIsOpenState}
          variant="link"
          eventKey="0"
          className="content-group-exercise-button"
        >
          <Container className="content-group-exercise-container">
            <Row className="content-group-exercise-row">
              <Col
                className={`${
                  isOpen === 1 ? "content-group-exercise-open" : ""
                }`}
                sm={11}
              >
                <Row className="content-group-exercise-row">
                  <Col className="content-group-exercise-normal">{title}</Col>
                </Row>
                <Row className="content-group-exercise-row">
                  <Col className="content-group-exercise-small">
                    {problemTemplateListsNumber}
                  </Col>
                </Row>
              </Col>
              <Col
                className={`${
                  isOpen === 1 ? "content-group-exercise-open" : ""
                } content-group-exercise-col-icon`}
              >
                <ExpandMoreIcon className="content-group-exercise-icon my-auto" />
              </Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey="0"
          className={`${isOpen === 1 ? "content-group-exercise-collapse" : ""}`}
        >
          <Card.Body className="content-group-exercise-card-body">
            {problemTemplateLists.map((problemTemplateList) => (
              <List3Exercise problemTemplateList={problemTemplateList} key={problemTemplateList.problemTemplateListId}/>
            ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default ContentGroupExercise;
