import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Container } from "react-bootstrap";
import ProblemListCreationStep4TopSection from "./ProblemListCreationStep4TopSection";

/**
 * ProblemListCreationStep4Article is a container for the main (article) area of the 4th step of the problem list creation progess.
 */
function ProblemListCreationStep4Article({ templateList }) {
    const [tabState, setTabState] = useState(1);

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    return (
        <Container fluid>
            <ProblemListCreationStep4TopSection
                tabState={tabState}
                handleTabStateChange={handleTabStateChange}
                templateList={templateList}
            />
        </Container>
    );
}

export default ProblemListCreationStep4Article;
