import { TEMPLATELIST_STATE_INIT, TEMPLATELIST_STATE_RESET } from "./actions";

const initialState = {
  subgroups: [],
  templateGuids: [],
  name: "matematika",
  filter: "subject-mathematics",
};

export const templatelistStateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === TEMPLATELIST_STATE_INIT) {
    const element = payload;
    return element;
  }

  if (type === TEMPLATELIST_STATE_RESET) {
    return initialState;
  }

  return state;
};
