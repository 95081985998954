import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ExerciseAssessedQuiz from "../exercise/quiz/ExerciseAssessedQuiz";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function StudentProblemListEvaluatedComponent({ problemList }) {

    const { t, i18n } = useTranslation();

    let points = 0;
    let maxPoints = 0;

    for (let i = 0; i < problemList.problems.length; i++) {
        points += problemList.problems[i].points;
        maxPoints += problemList.problems[i].maxPoints;
    }

    return (
        <>
            <Helmet>
                <title>
                    {t("Exercise-Details.Exercise-Main.title", {
                        title: problemList.title === ""
                            ? t("Exercise-Details.Exercise-Main.empty-title")
                            : problemList.title,
                    })}
                </title>
                <meta name="description" content={t("Exercise-Details.Exercise-Main.description",t("Global.description"))} />
                <meta name="keywords" content={t("Exercise-Details.Exercise-Main.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Container fluid>
                <Row className="create-row without-tabs">
                    <Col sm={1}></Col>
                    <Col sm={9} md={7} className="create-col-middle">
                        <h3>{problemList.title === "" ? t("Exercise-Details.Exercise-Main.empty-title") : problemList.title}</h3>
                        <p className="small-body-text">{problemList.description}</p>
                        <p className="small-body-text">
                            {problemList.evaluationNotes
                                ? t("Student.Assessed-Exercise.evaluator-feedback")
                                : ""}
                        </p>
                        <p className="normal-body-text">{problemList.evaluationNotes}</p>

                        <Button
                            bsPrefix="toolbar"
                            className="btn secondary-button secondary-button-text block"
                            style={{ display: "none" }}
                        >
                            {t("Student.Assessed-Exercise.faulty-problem-exercise")}
                        </Button>
                    </Col>
                    <Col sm={12} md={4}>
                        <p>{t("Student.Assessed-Exercise.result")}</p>
                        <div style={{ textAlign: "center" }}>
                            <h1 style={{ lineHeight: "100%" }}>
                                {parseInt((points / maxPoints) * 100)}%
              </h1>
                            <p className="small-body-text">
                                {points}/{maxPoints} {t("Exercise-Details.Exercise-Main.points")}
              </p>
                        </div>
                        <p style={{ display: "none" }}>
                            <ShareOutlinedIcon className="icon" />{" "}
                            {t("Student.Assessed-Exercise.share")}: <a href="">Facebook </a> | <a href="">Twitter </a> | <a href="">Instagram</a>
                        </p>
                    </Col>
                </Row>
                <Row className="create-row-bottom">
                    <Col sm={1}></Col>
                    <Col sm={10}>
                        {problemList.problems.map((problem, index) => (
                            <ExerciseAssessedQuiz
                                problem={problem}
                                key={problem.guid}
                                index={index}
                            />
                        ))}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default StudentProblemListEvaluatedComponent;
