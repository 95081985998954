import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Card, Badge, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { GreyCheckbox } from "../modification/Checkbox";

function List1({ template, handleCategorySelectChange, subcategories }) {

    const { t } = useTranslation();

    return (
        <Card onClick={() => handleCategorySelectChange(template.filter)}>
            <Container className="list1-container">
                <Row className="list1-row">
                    <Col className="list1-col-left">
                        <Card.Body className="list1-card-body">
                            {template.name}
                            <Badge className="pill-large list-pill">
                                {template.templateGuids.length}
                            </Badge>
                        </Card.Body>
                    </Col>
                    <Col className="list1-col-right">
                        <FormControlLabel
                            control={
                                <GreyCheckbox
                                    inputProps={{ "aria-label": "checkbox with default color" }}
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                    name={template.filter}
                                    checked={subcategories.includes(template.filter)}
                                />
                            }
                            label={t("Create.Create-1-Tasks.select")}
                            htmlFor={template.filter}
                            className="list1-select-subcategory-label"
                        />
                    </Col>
                </Row>
            </Container>
        </Card>
    );
}

export default List1;
