import axios from "axios";
import { checkRequirement } from "../helpers";
import { initProblemTemplateListState } from "../state/problemtemplatelist/actions";
import { initErrorState } from "../state/error/actions";

export function publishProblemTemplateList(
    cookies,
    dispatch,
    history,
    isPublic,
    exercideId
) {
    if (checkRequirement(cookies)) {
        axios
            .patch(
                `${process.env.REACT_APP_BASE_API_URL}/problemtemplatelist/publish/${exercideId}`,
                isPublic,
                {
                    headers: {
                        "X-Authenticated-User": cookies.guid,
                    },
                }
            )
            .then((res) => {
                dispatch(initProblemTemplateListState(res.data));
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
