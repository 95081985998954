import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/exercise.css";
import "../../../styles/create.css";
import { Card, Row, Col, Container, Badge } from "react-bootstrap";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";

function ExerciseViewQuiz({ problem, index }) {
    const { t } = useTranslation();

    return (
        <Card className={`exercise-create-card`}>
            <Container className="exercise-create-container">
                <Row className="exercise-create-row">
                    <Col xs={9} md={9}>
                        <Row className="exercise-create-row">
                            <Col className="exercise-create-normal large-body-text exercise-border">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: index + 1 + ". " + problem.question,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="exercise-create-row">
                            <Col className="exercise-create-normal">
                                {problem.solutionSteps.sort((a, b) => a.index - b.index).map((solutionStep, index) => {
                                    return (
                                        <div key={index}>
                                            <p>{index + 1}.</p>
                                            {solutionStep.offeredSolutionStepOptions.sort((a, b) => a.index - b.index).map(
                                                (solutionStepOption, index) => {
                                                    return (
                                                        <Badge
                                                            className={`multiple-choise-answer-view ${index === solutionStep.selectedSolutionStepOptionIndex ? "multiple-choise-answer-view-selected" : ""}`}
                                                            key={solutionStepOption.guid}
                                                            dangerouslySetInnerHTML={{
                                                                __html: solutionStepOption.presentation,
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                        <Row className="exercise-create-row">
                            <Col>
                                <p>{problem.notes}</p>
                            </Col>
                            <Col>
                                <p
                                    style={{
                                        textAlign: "right",
                                        color: problem.status === "Correct" ? "green" : "red",
                                    }}
                                >
                                    {problem.points} / {problem.maxPoints} {t("Exercise.points")}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={3} md={3}>
                        <Row className="exercise-create-row">
                            <Col>
                                {problem.status === "Correct" ? (
                                    <CheckCircleOutlineOutlinedIcon
                                        style={{ color: "green" }}
                                        className="icon"
                                    />
                                ) : (
                                    <CancelOutlinedIcon
                                        style={{ color: "red" }}
                                        className="icon"
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
}

export default ExerciseViewQuiz;
