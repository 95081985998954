import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row, Button, Form } from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

/**
 * ProblemListCreationStep1TopSection is a container for the top section of the 1st step of the problem list creation process.
 */
function ProblemListCreationStep1TopSection({ tabState, handleChange, templateList }) {
    const { t, i18n } = useTranslation();
    const description = t("Create.Create-1-Main.description")!="Create.Create-1-Main.description"

    return (
        <>
            <Helmet>
                <title>{t("Create.Create-1-Main.title",t("Global.title"))}</title>
                <meta name="description" content={t("Create.Create-1-Main.description",t("Global.description"))} />
                <meta name="keywords" content={t("Create.Create-1-Main.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Row className="create-row has-tabs">
                <Col className="inner-col">
                    <Row className="inner-row">
                        <Col sm={1}></Col>
                        <Col sm={9} md={7} className="create-col-middle">
                            <Row className="form-row">
                                <Col sm={10} className="form-col">
                                    <Form>
                                        <Form.Group controlId="title">
                                            <Form.Label>
                                                {t("Create.Create-1-Main.exercise-title")}
                                            </Form.Label>
                                            <Form.Control
                                                name="title"
                                                type="text"
                                                placeholder={t("Create.Create-1-Main.exercise-title-placeholder")}
                                                value={templateList.title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>
                                                {t("Create.Create-1-Main.exercise-description")}
                                            </Form.Label>
                                            <Form.Control
                                                name="description"
                                                as="textarea"
                                                rows="3"
                                                placeholder={t("Create.Create-1-Main.exercise-description-placeholder")}
                                                value={templateList.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={4}>
                            <a href="/" className="remove-exercise" style={{ display: 'none' }}>
                                <DeleteOutlinedIcon className="icon" />
                                {t("Create.Create-1-Main.delete-exercise")}
                            </a>
                        </Col>
                    </Row>
                    <Row className="inner-row" style={{ display: 'none' }}>
                        <Col sm={1}></Col>
                        <Col sm={9} md={7}>
                            <Button
                                bsPrefix="toolbar"
                                className={`btn toolbar-button ${
                                    tabState === 0 ? "toolbar-button-active" : ""
                                    }`}
                            >
                                <HelpOutlineIcon className="icon" />
                                {t("Create.Create-1-Main.exercises")}
                            </Button>
                            <Button
                                bsPrefix="toolbar"
                                className="btn toolbar-button btn-disabled"
                            >
                                <SettingsIcon className="icon icon-tab" />
                                {t("Create.Create-1-Main.settings")}
                            </Button>
                        </Col>
                        <Col sm={12} md={4}></Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ProblemListCreationStep1TopSection;
