import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import { Col, Row, Form } from "react-bootstrap";
import { FormControlLabel } from "@mui/material";
import { BlackCheckbox } from "../modification/Checkbox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { BlackRadio } from "../modification/Radio";
import { useTranslation } from "react-i18next";

function ProblemListCreationSettingsSection({
    handleChange,
    templateList,
    handleRadioChange,
    handleCheckboxChange,
    handleTimeLimitChange,
    handleExpireDateUTCChange,
    timeLimitHour,
    handleTimeLimitHourChange,
    expireDate,
    timeLimitMinute,
    handleTimeLimitMinuteChange,
    expireHour,
    handleExpireHourChange,
    expireMinute,
    handleExpireMinuteChange,
    handleExpireDateChange,
}) {
    useEffect(() => {
        if (timeLimitHour !== "" || timeLimitMinute !== "") {
            handleTimeLimitChange(timeLimitHour * 3600 + timeLimitMinute * 60);
        } else {
            handleTimeLimitChange(0);
        }
    }, [timeLimitHour, timeLimitMinute]);

    useEffect(() => {
        let newDate;
        if (expireDate !== "") {
            newDate = new Date(
                expireDate.split("-")[0],
                expireDate.split("-")[1] - 1,
                expireDate.split("-")[2],
                expireHour,
                expireMinute
            ).toISOString();
            handleExpireDateUTCChange(newDate);
        }
    }, [expireDate, expireHour, expireMinute]);

    const { t } = useTranslation();


    return (
        <Row className="dashboard-row">
            <Col sm={1} md={1}></Col>
            <Col sm={10} className="dashboard-col-middle">
                <h2>{t("Create.Settings.settings")}</h2>
                <Form>
                    <Row className="inner-row">
                        <Col className="inner-col" sm={6}>
                            <Form.Group controlId="title">
                                <Form.Label>{t("Create.Settings.exercise-title")}</Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    value={templateList.title}
                                    placeholder={t("Create.Settings.exercise-title-placeholder")}
                                    onChange={handleChange}
                                    maxLength="70"
                                />
                            </Form.Group>

                            <Form.Group controlId="details">
                                <Form.Label>
                                    {t("Create.Settings.exercise-description")}
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    as="textarea"
                                    rows="3"
                                    value={templateList.description}
                                    placeholder={t("Create.Settings.exercise-description-placeholder")}
                                    onChange={handleChange}
                                    maxLength="1000"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ display: 'none' }}>
                        <h4 className="settings-header">
                            {t("Create.Settings.visualization")}
                        </h4>

                        <BlackRadio
                            onChange={handleRadioChange}
                            checked={templateList.onePageOneProblem === false}
                            value="false"
                            name="onePageOneProblem"
                            inputProps={{ "aria-label": "0" }}
                        />
                        <span>{t("Create.Settings.all-exercise-in-one-page")}</span>
                        <BlackRadio
                            onChange={handleRadioChange}
                            checked={templateList.onePageOneProblem === true}
                            value="true"
                            name="onePageOneProblem"
                            inputProps={{ "aria-label": "1" }}
                        />
                        <span>{t("Create.Settings.one-page-one-exercise")}</span>

                    </div>

                    <h4 className="settings-header">{t("Create.Settings.restrictions")}</h4>

                    <div style={{ display: 'none' }}>
                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                />
                            }
                            onChange={handleCheckboxChange}
                            checked={templateList.allProblemsAreMandatory === true}
                            name="allProblemsAreMandatory"
                            label={t("Create.Settings.all-problems-are-mandatory")}
                            className="block-checkbox"
                        />
                    </div>

                    <FormControlLabel
                        control={
                            <BlackCheckbox
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={<CheckBoxOutlinedIcon />}
                            />
                        }
                        checked={
                            (parseInt(timeLimitHour) !== 0 && timeLimitHour !== "") ||
                            (parseInt(timeLimitMinute) !== 0 && timeLimitMinute !== "")
                        }
                        label={t("Create.Settings.time-limit-title")}
                        className="block-checkbox"
                    />

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label>{t("Create.Settings.hour")}</Form.Label>
                        <Form.Control
                            value={timeLimitHour}
                            onChange={(e) => handleTimeLimitHourChange(e.target.value)}
                            type="number"
                            placeholder="0"
                            maxLength="1"
                            style={{ width: "50px" }}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label>{t("Create.Settings.minute")}</Form.Label>
                        <Form.Control
                            value={timeLimitMinute}
                            onChange={(e) => handleTimeLimitMinuteChange(e.target.value)}
                            type="number"
                            placeholder="00"
                            maxLength="2"
                            style={{ width: "50px" }}
                        />
                    </Form.Group>

                    <FormControlLabel
                        control={
                            <BlackCheckbox
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={<CheckBoxOutlinedIcon />}
                            />
                        }
                        checked={
                            expireDate !== "" ||
                            (parseInt(expireHour) !== 0 && expireHour !== "") ||
                            (parseInt(expireMinute) !== 0 && expireMinute !== "")
                        }
                        label={t("Create.Settings.inactive-link")}
                        className="block-checkbox"
                    />

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label>{t("Create.Settings.date")}</Form.Label>
                        <Form.Control
                            value={expireDate}
                            onChange={(e) => handleExpireDateChange(e.target.value)}
                            type="date"
                            placeholder="NN/HH/EEEE"
                            style={{ width: "200px" }}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label>{t("Create.Settings.hour")}</Form.Label>
                        <Form.Control
                            value={expireHour}
                            onChange={(e) => handleExpireHourChange(e.target.value)}
                            type="number"
                            placeholder="00"
                            style={{ width: "50px" }}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label>{t("Create.Settings.minute")}</Form.Label>
                        <Form.Control
                            value={expireMinute}
                            onChange={(e) => handleExpireMinuteChange(e.target.value)}
                            type="number"
                            placeholder="00"
                            style={{ width: "50px" }}
                        />
                    </Form.Group>
                    <span className="inner-element">CET</span>

                    <div style={{ display: 'none' }}>
                        <h4 className="settings-header">{t("Create.Settings.evaluation")}</h4>

                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                />
                            }
                            name="variablePointsPerProblem"
                            onChange={handleCheckboxChange}
                            checked={templateList.variablePointsPerProblem === true}
                            label={t("Create.Settings.exercises-worth-point")}
                            className="block-checkbox"
                        />

                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                />
                            }
                            name="automatedProblemEvaluation"
                            onChange={handleCheckboxChange}
                            checked={templateList.automatedProblemEvaluation === true}
                            label={t("Create.Settings.automate-check")}
                            className="block-checkbox"
                        />
                    </div>
                </Form>
            </Col>
        </Row>
    );
}

export default ProblemListCreationSettingsSection;
