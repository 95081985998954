import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { getRandomProblems } from "../../../api/GetRandomProblems";
import { getRandomProblemsState } from "../../../state/randomproblems/selectors";
import { getTemplatelistState } from "../../../state/templatelist/selectors";
import ProblemTemplateSetNumberComponent from "../../../views/exercise/ProblemTemplateSetNumberComponent";
import { useLanguageCode } from "../../../helpers";

/**
 * ProblemListCreationStep2CentralSection is a container for the central section of the 2nd step of the problem list creation process.
 */
function ProblemListCreationStep2CentralSection({
    subcategories,
    createJourneyState,
    problemTemplateNumberChangeEventHandler,
    templateList,
}) {
    const templatelist = useSelector(getTemplatelistState);
    const [cookies] = useCookies(["guid"]);
    const dispatch = useDispatch();
    const stateValues = useSelector(getRandomProblemsState);
    const { t, i18n } = useTranslation();
    const language = useLanguageCode();

    const currentState = stateValues[stateValues.length - 1];

    useEffect(() => {
        if (stateValues.length === 0) {
            templatelist.subgroups.map((template) => {
                return template.subgroups.map((templateSub) => {
                    return subcategories.includes(templateSub.filter) ? (
                        <>
                            <h4>{templateSub.name}</h4>
                            {templateSub.templateGuids.forEach((templateGuid) => {
                                getRandomProblems(
                                    cookies,
                                    dispatch,
                                    templateGuid,
                                    1,
                                    language
                                );
                            })}
                        </>
                    ) : (
                            ""
                        );
                });
            });
        }
    }, [
        cookies,
        createJourneyState,
        dispatch,
        i18n.language,
        stateValues.length,
        subcategories,
        templatelist.subgroups,
    ]);

    return (
        <Row className="create-row-bottom">
            <Col sm={1}></Col>
            <Col sm={10} lg={10} xl={10}>
                <h3>{t("Create.Create-2-Tasks.choose-exercise-types")}</h3>
                {templatelist.subgroups.map((template) => {
                    return template.subgroups.map((templateSub) => {
                        if (subcategories.includes(templateSub.filter)) {
                            return (
                                <div key={templateSub.filter}>
                                    <h4>{templateSub.name}</h4>
                                    {templateSub.templateGuids.map((templateGuid, index) => {
                                        return (
                                            <ProblemTemplateSetNumberComponent
                                                key={templateGuid}
                                                state={currentState}
                                                problemTemplateGuid={templateGuid}
                                                problemTemplate={
                                                    stateValues.filter(
                                                        (problem) => problem.templateGuid === templateGuid
                                                    )[0]
                                                }
                                                problemTemplateNumberChangeEventHandler={problemTemplateNumberChangeEventHandler}
                                                templateList={templateList}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        } else {
                            return "";
                        }
                    });
                })}
            </Col>
        </Row>
    );
}

export default ProblemListCreationStep2CentralSection;
