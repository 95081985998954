import axios from "axios";
import { checkRequirement } from "../helpers";
import { initErrorState } from "../state/error/actions";

export function evaluateProblemList(
    cookies,
    dispatch,
    history,
    language,
    submissionId,
    evaluateList
) {
    if (checkRequirement(cookies)) {
        axios
            .patch(
                `${process.env.REACT_APP_BASE_API_URL}/problemlist/evaluate/${submissionId}`,
                evaluateList,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authenticated-User": cookies.guid,
                        "Accept-Language": language,
                    },
                }
            )
            .then((res) => {})
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
