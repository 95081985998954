import {
    RANDOMPROBLEMS_STATE_INIT,
    RANDOMPROBLEMS_STATE_RESET,
    RANDOMPROBLEMS_STATE_ERROR,
    RANDOMPROBLEMS_STATE_LOADING,
    RANDOMPROBLEMS_STATE_SUCCESS,
    RANDOMPROBLEMS_STATE_SWAPTWO
} from "./actions";

const initialState = [];

const faultyTemplateState = {
    hash: "",
    notes: "",
    problemIndex: 0,
    question: "",
    tags: [],
    templateGuid: "",
    templateId: "",
};

export const randomProblemsStateReducer = (state = initialState, action) => {
    const { type, payload } = action;

    if (type === RANDOMPROBLEMS_STATE_INIT) {
        const elements = state;
        const element = payload;

        if (element !== undefined) {
            return [...elements, element, type];
        }
    }

    if (type === RANDOMPROBLEMS_STATE_RESET) {
        return initialState;
    }

    if (type === RANDOMPROBLEMS_STATE_ERROR) {
        const elements = state;
        const error = payload[1];
        return [...elements, error, type];
    }

    if (type === RANDOMPROBLEMS_STATE_LOADING) {
        const elements = state;
        const problemTemplateGuid = payload;
        return [...elements, problemTemplateGuid, type];
    }

    if (type === RANDOMPROBLEMS_STATE_SUCCESS) {
        const elements = state;
        const problem = payload;

        const id = elements.findIndex((e) => e === problem.templateGuid);
        elements[id] = problem;

        return [...elements, type];
    }

    if (type === RANDOMPROBLEMS_STATE_SWAPTWO) {
        const elements = state;
        const element = payload;
        const firstGuidIndex = elements.findIndex(
            (e) => (e.templateGuid === element.firstGuid)
        );
        const secondGuidIndex = elements.findIndex(
            (e) => (e.templateGuid === element.secondGuid)
        );

        const tmp = elements[firstGuidIndex];
        elements[firstGuidIndex] = elements[secondGuidIndex];
        elements[secondGuidIndex] = tmp;

        return [...elements, type];
    }

    return state;
};
