import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Container } from "react-bootstrap";
import ProblemListCreationStep1TopSection from "./ProblemListCreationStep1TopSection";
import ProblemListCreationStep1CentralSection from "./ProblemListCreationStep1CentralSection";

/**
 * ProblemListCreationStep1Article is a container for the main (article) area of the 1st step of the problem list creation progess.
 */
function ProblemListCreationStep1Article({
    subject,
    handleSubjectStateChange,
    handleChange,
    templateList,
    handleCategorySelectChange,
    handleDeleteExercise,
    subcategories,
    handleSearchedKeyChange
}) {
    const [tabState, setTabState] = useState(0);

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    return (
        <article>
            <Container fluid>
                <ProblemListCreationStep1TopSection
                    tabState={tabState}
                    handleChange={handleChange}
                    templateList={templateList}
                    handleDeleteExercise={handleDeleteExercise}
                />
                <ProblemListCreationStep1CentralSection
                    subject={subject}
                    handleSubjectStateChange={handleSubjectStateChange}
                    handleTabStateChange={handleTabStateChange}
                    handleCategorySelectChange={handleCategorySelectChange}
                    subcategories={subcategories}
                    handleSearchedKeyChange={handleSearchedKeyChange}
                />
            </Container>
        </article>
    );
}

export default ProblemListCreationStep1Article;
