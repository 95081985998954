import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import {
    Navbar,
    Nav,
    Button,
    Image,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
import { showNotification } from '../../helpers.js'


function ToolbarNavbar({
    onCreateJourneyChange,
    navbarTitleTop,
    navbarTitleBottom,
    isButtonEnabled,
    createJourneyState: journeyStepIndex,
    handleCreateProblemTemplateList,
}) {
    const { t } = useTranslation();

    const handleNextJourneyState = () => {
        if (isButtonEnabled)
        {
            if (journeyStepIndex + 1 !== 3) {
                onCreateJourneyChange(1);
            } else {
                handleCreateProblemTemplateList();
            }
        } else 
        {
            var notificationMessage = (journeyStepIndex === 0 ? t("Navbar.Toolbar-Navbar.new-problem-list-01-validation") :
                                        journeyStepIndex === 1 ? t("Navbar.Toolbar-Navbar.new-problem-list-02-validation") :
                                        t("Global.validation-error"));

            showNotification(notificationMessage);
        }
    };

    return (
        <Navbar className="navbar-button-right source-file-ToolbarNavbar" fixed="top">
            <Navbar.Brand href="/">
                <Image src={LogoWhite} className="logo" rounded />
            </Navbar.Brand>
            <Navbar.Brand>
                <Container className="nav-container">
                    <Row className="nav-container-row">
                        <Col>
                            <span className="navbar-title-top">{navbarTitleTop}</span>
                            <br></br>
                            <span className="navbar-title-bottom">{navbarTitleBottom}</span>
                        </Col>
                    </Row>
                </Container>
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <Nav className="navbar-right">
                <Nav.Link className="new-task" href="/feature" style={{ display: "none" }}>
                    <FilterListIcon className="icon not-button-icon" />
                    {t("Navbar.Toolbar-Navbar.filter-problem")}
                </Nav.Link>
            </Nav>
            <Button
                bsPrefix="toolbar"
                className={`btn navbar-button ${!isButtonEnabled ? "toolbar-button-disabled" : ""}`}
                onClick={() => handleNextJourneyState()}
            >
                <ArrowForwardIosIcon className="icon button-icon" />{" "}
                {t("Navbar.Toolbar-Navbar.next")}
            </Button>
        </Navbar>
    );
}

export default ToolbarNavbar;
