import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/min/locales";
import { publishProblemTemplateList } from "../../../api/PublishProblemTemplateList";
import { getProblemTemplateListState } from "../../../state/problemtemplatelist/selectors";
import { BlackCheckbox } from "../../../views/modification/Checkbox";
import { useLanguageCode } from "../../../helpers"

/**
 * ProblemListCreationStep4TopSection is a container for the top section of the 4th step of the problem list creation process.
 */
function ProblemListCreationStep4TopSection({ templateList }) {
    const [cookies] = useCookies(["guid"]);
    const history = useHistory();
    const problemTemplateList = useSelector(getProblemTemplateListState);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const languageCode = useLanguageCode();

    const copyToClipboard = (text) => {
        const link = `${process.env.REACT_APP_BASE_URL}${text}`;
        navigator.clipboard.writeText(link);
    };

    const isPublicState = {
        isPublic: !problemTemplateList.isPublic,
    };

    const expirationTime = moment
        .utc(problemTemplateList.expiresAt)
        .local()
        .locale(i18n.language);

    const expirationTimeISO = expirationTime.format();

    const expirationTimeHumanReadable = expirationTime.format("YYYY. MMMM D. H:mm");

    return (
        <>
            <Helmet>
            <title>{t("Create.Create-4-Main.title",t("Global.title"))}</title>
                <meta name="description" content={t("Create.Create-4-Main.description",t("Global.description"))} />
                <meta name="keywords" content={t("Create.Create-4-Main.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Row className="create-row">
                <Col sm={1}></Col>
                <Col sm={9} md={9}>
                    <Row className="inner-row">
                        <Col sm={4} md={3} className="success-icon-col">
                            <CheckCircleOutlineOutlinedIcon className="icon-success" />
                        </Col>
                        <Col sm={8} md={9} className="success-middle-col">
                            <h3>{t("Create.Create-4-Main.exercise-ready")}</h3>
                            <p className="normal-body-text">
                                {t("Create.Create-4-Main.exercise-ready-to-receive-solutions")}
                            </p>
                            <p className="small-body-text">
                                {t("Create.Create-4-Main.tasks-and-points", {
                                    taskNumber: problemTemplateList.templateGuids.length,
                                    maxPoint: problemTemplateList.maxPoints.reduce(
                                        (a, b) => a + b,
                                        0
                                    ),
                                })}
                            </p>
                            <p
                                className="small-body-text"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        (isNaN(problemTemplateList.expiresAt)) ?
                                            t("Create.Create-4-Main.inactive-link-date", {
                                                inactiveDate: `<time datetime="${expirationTimeISO}">` + expirationTimeHumanReadable + "</time>"
                                            }) : t("Create.Create-4-Main.inactive-link-nodate")
                                }}
                            ></p>
                            <Form>
                                <Form.Group controlId="problemTemplateListId">
                                    <Form.Label>
                                        {t("Create.Create-4-Main.link-for-students")}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={`${process.env.REACT_APP_BASE_URL}${
                                            problemTemplateList === undefined
                                                ? ""
                                                : problemTemplateList.id
                                            }`}
                                        readOnly
                                    />
                                </Form.Group>
                                <Button
                                    bsPrefix="toolbar"
                                    className="btn link-without-redirection"
                                    onClick={() => copyToClipboard(problemTemplateList.id)}
                                    style={{ color: "black", paddingRight: "15px" }}
                                >
                                    <BookmarksOutlinedIcon className="icon"></BookmarksOutlinedIcon>
                                    {t("Create.Create-4-Main.copy-link")}
                                </Button>
                                <FormControlLabel
                                    control={
                                        <BlackCheckbox
                                            inputProps={{
                                                "aria-label": "checkbox with default color",
                                            }}
                                            icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                            checkedIcon={<CheckBoxOutlinedIcon />}
                                            onClick={() =>
                                                publishProblemTemplateList(
                                                    cookies,
                                                    dispatch,
                                                    history,
                                                    isPublicState,
                                                    problemTemplateList.id
                                                )
                                            }
                                        />
                                    }
                                    label={t("Create.Create-4-Main.active-link")}
                                    checked={problemTemplateList.isPublic}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={1}></Col>
            </Row>
        </>
    );
}

export default ProblemListCreationStep4TopSection;
