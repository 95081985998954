import { PROBLEMLIST_STATE_INIT, PROBLEMLIST_STATE_RESET, PROBLEMLIST_STATE_LOADING, PROBLEMLIST_STATE_SUCCESS } from "./actions";

export const initialState = {    
    id: null,
    problemTemplateListId: null,
    author: null,
    title: null,
    description: null,
    timeLimit: 0,
    hash: null,
    culture: null,
    authorUserGuid: null,
    userGuid: null,
    createdAt: null,
    firstOpenedAt: null,
    submittedAt: null,
    expiresAt: null,
    deletedAt: null,
    lastSolutionSubmittedAt: null,
    evaluationNotes: null,
    evaluationCompletedAt: null,
    problems: [],
};

export const problemListStateReducer = (state = initialState, action) => {
    const { type, payload } = action;

    if (type === PROBLEMLIST_STATE_INIT) {
        const element = payload;
        return element;
    }

    if (type === PROBLEMLIST_STATE_RESET) {
        return initialState;
    }

    if (type === PROBLEMLIST_STATE_LOADING) {
        const problemTemplateListId = payload;
        return state;
    }

    if (type === PROBLEMLIST_STATE_SUCCESS) {
        const problemList = payload;

        return problemList;
    }

    return state;
};
