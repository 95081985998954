import React, { useCallback, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.css";
import { BrowserRouter , Switch, Route, useHistory, Redirect } from "react-router-dom";
import NavbarProvider from "./navbar/NavbarProvider";
import Dashboard from "./dashboard/Dashboard";
import ProblemListCreationJourneyProvider from "./create/ProblemListCreationJourneyProvider";
import { useState } from "react";
import PageNotFound from "./pagenotfound/PageNotFound";
import Register from "./register/Register";
import Login from "./login/Login";
import Profile from "./profile/Profile";
import Error from "./error/Error";
import ExerciseDetailsProvider from "./exercisedetails/ExerciseDetailsProvider";
import ExerciseSubmissionProvider from "./exercisedetails/ExerciseSubmissionProvider";
import { submitProblemList } from "../api/SubmitProblemList";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import StudentJourneyProvider from "./student/StudentJourneyProvider";
import Feature from "./feature/Feature";
import { randomProblemComponentChangeStateToReset } from "../state/randomproblems/actions";
import { studentProblemSetComponentChangeStateToReset } from "../state/problemlist/actions";
import { useDispatch } from "react-redux";
import ReactNotification from "react-notifications-component"
import { Helmet } from "react-helmet";
import "react-notifications-component/dist/theme.css"
import 'animate.css';

function App() {
    const history = useHistory();
    const [getNavigationBarMode, setNavigationBarMode] = useState("Guest");
    const [createJourneyState, setCreateJourneyState] = useState(0);
    const [navbarTitleTop, setNavbarTitleTop] = useState("");
    const [navbarTitleBottom, setNavbarTitleBottom] = useState("");
    const [saveProfileState, setSaveProfileState] = useState(0);
    const [currentProblemListId, setCurrentProblemListId] = useState();
    const [
        sendCreateProblemTemplateList,
        setSendCreateProblemTemplateList,
    ] = useState(0);
    const [
        currentProblemTemplateListId,
        setCurrentProblemTemplateListId,
    ] = useState();
    const [evaluationState, setEvaluationState] = useState(0);
    const [cookies, setCookie] = useCookies(["guid"]);
    const [canClickOnNextCreate, setCanClickOnNextCreate] = useState(false);
    const [canClickStudentSubmit, setCanClickStudentSubmit] = useState(false);
    const { t , i18n } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        setLanguage();

        handleRedirecting();

        if (cookies.guid === undefined) {
            setCookie("guid", uuidv4(), {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
        } 
    },[]);

    const handleRedirecting = (lang) => {

        var code="en-US"
        if(i18n.language==="en") code="en-US";
        if(i18n.language==="hu") code="hu-HU";

        if(window.location.href === 'https://www.praktiko.org/') {
            window.location.assign("https://www.praktiko.org/en-US")
        }   

        if(window.location.href === 'https://www.feladatsorok.hu/') {
            window.location.assign('www.praktiko.org/hu-HU')
        }

        if(window.location.pathname === "/" ) {
            if(cookies.lang!==undefined) {
                if(cookies.lang==="en") {
                    i18n.changeLanguage("en");
                    window.location.assign("/en-US");
                }
                if(cookies.lang==="hu") {
                    i18n.changeLanguage("hu");
                    window.location.assign("/hu-HU");
                } 
            } else 
                if(code==="en-US") window.location.assign("/en-US");
                else window.location.assign("/hu-HU");
        }

        if(window.location.pathname === "/create" ) {
            if(cookies.lang!==undefined) {
                if(cookies.lang==="hu") window.location.assign("/hu-HU/create");
                if(cookies.lang==="en") window.location.assign("/en-US/create");
            } else 
                if(code==="hu-HU") window.location.assign("/hu-HU/signin");
                else window.location.assign("/en-US/signin");
        }

        if(window.location.pathname === "/signin" ) {
            if(cookies.lang!==undefined) {
                if(cookies.lang==="hu") window.location.assign("/hu-HU/signin");
                if(cookies.lang==="en") window.location.assign("/en-US/signin");
            } else 
                if(code==="hu-HU") window.location.assign("/hu-HU/signin");
                else window.location.assign("/en-US/signin");
        }

        if(window.location.pathname === "/registration" ) {
            if(cookies.lang!==undefined) {
                if(cookies.lang==="hu") window.location.assign("/hu-HU/registration");
                if(cookies.lang==="en") window.location.assign("/en-US/registration");
            } else 
                if(code==="hu-HU") window.location.assign("/hu-HU/registration");
                else window.location.assign("/en-US/registration");
        }

        if(window.location.pathname === "/profile" ) {
            if(cookies.lang!==undefined) {
                if(cookies.lang==="hu") window.location.assign("/hu-HU/profile");
                if(cookies.lang==="en") window.location.assign("/en-US/profile");
            } else 
                if(code==="hu-HU") window.location.assign("/hu-HU/profile");
                else window.location.assign("/en-US/profile");
        }
    }

    const setLanguage = () => {
        const en = window.location.href.includes("en-US");
        const hu = window.location.href.includes("hu-HU");

        if (en) {
            i18n.changeLanguage("en");
            setCookie("lang", "en", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
        } else if(hu) {
            i18n.changeLanguage("hu");
            setCookie("lang", "hu", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
        } else if(cookies.lang!==undefined) {
            i18n.changeLanguage(cookies.lang);
        }
    }

    const changeNavigationBarModeTo = (newState) => {
        setNavigationBarMode(newState);
    };

    const handleNavbarTitleTop = (newTitle) => {
        setNavbarTitleTop(newTitle);
    };

    const handleNavbarTitleBottom = (newTitle) => {
        setNavbarTitleBottom(newTitle);
    };

    const handleCreateJourneyState = (newState) => {
        setCreateJourneyState(createJourneyState + newState);

        if (newState < 0 && createJourneyState + newState === 0) {
            dispatch(randomProblemComponentChangeStateToReset());
        }
    };

    const resetCreateJourneyState = useCallback(() => {
        setCreateJourneyState(0);
    }, []);

    const handleSaveProfile = () => {
        setSaveProfileState(1);
    };

    const handleSubmitProblemList = () => {
        submitProblemList(cookies, dispatch, history, currentProblemListId);
    };

    const problemListIdChanged = (newProblemListId) => {
        setCurrentProblemListId(newProblemListId);
        console.log("event: problemListIdChanged, the new problem list id is " + newProblemListId);
    };

    const handleEvaluateProblemList = () => {
        setEvaluationState(1);
    };

    const handleCreateProblemTemplateList = () => {
        setSendCreateProblemTemplateList(1);
    };

    var lang="en";

    if(cookies.lang!=undefined) lang=cookies.lang;
    else lang=i18n.language;

    return (
        <BrowserRouter>
            <Helmet>
                <title>{t("Global.title")}</title>
                <meta name="description" content={t("Global.description")} />
                <meta name="keywords" content={t("Global.keywords")} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <ReactNotification />
            <NavbarProvider
                navbarState={getNavigationBarMode}
                onCreateJourneyChange={handleCreateJourneyState}
                navbarTitleTop={navbarTitleTop}
                navbarTitleBottom={navbarTitleBottom}
                handleSaveProfile={handleSaveProfile}
                handleSubmitProblemList={handleSubmitProblemList}
                handleEvaluateProblemList={handleEvaluateProblemList}
                currentProblemListId={currentProblemListId}
                currentProblemTemplateListId={currentProblemTemplateListId}
                canClickOnNextCreate={canClickOnNextCreate}
                createJourneyState={createJourneyState}
                handleCreateProblemTemplateList={handleCreateProblemTemplateList}
                canClickStudentSubmit={canClickStudentSubmit}
            />
            <Switch>
                <Route exact path="/">
                  {
                      lang == "hu" ? <Redirect to={{ pathname: '/hu-HU', state: { status: 302 } }}/> :
                                     <Redirect to={{ pathname: '/en-US', state: { status: 302 } }}/>
                  }  
                </Route>
                <Route exact path="/hu-HU">
                    <Dashboard
                        onNavbarChange={changeNavigationBarModeTo}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                    />
                </Route>
                <Route exact path="/en-US">
                    <Dashboard
                        onNavbarChange={changeNavigationBarModeTo}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                    />
                </Route>
                <Route exact path="/create">
                    <ProblemListCreationJourneyProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        createJourneyState={createJourneyState}
                        handleCreateJourneyState={handleCreateJourneyState}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                        resetCreateJourneyState={resetCreateJourneyState}
                        setCanClickOnNextCreate={setCanClickOnNextCreate}
                        sendCreateProblemTemplateList={sendCreateProblemTemplateList}
                        setSendCreateProblemTemplateList={setSendCreateProblemTemplateList}
                    />
                </Route>
                <Route exact path="/hu-HU/create">
                    <ProblemListCreationJourneyProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        createJourneyState={createJourneyState}
                        handleCreateJourneyState={handleCreateJourneyState}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                        resetCreateJourneyState={resetCreateJourneyState}
                        setCanClickOnNextCreate={setCanClickOnNextCreate}
                        sendCreateProblemTemplateList={sendCreateProblemTemplateList}
                        setSendCreateProblemTemplateList={setSendCreateProblemTemplateList}
                    />
                </Route>
                <Route exact path="/en-US/create">
                    <ProblemListCreationJourneyProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        createJourneyState={createJourneyState}
                        handleCreateJourneyState={handleCreateJourneyState}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                        resetCreateJourneyState={resetCreateJourneyState}
                        setCanClickOnNextCreate={setCanClickOnNextCreate}
                        sendCreateProblemTemplateList={sendCreateProblemTemplateList}
                        setSendCreateProblemTemplateList={setSendCreateProblemTemplateList}
                    />
                </Route>
                <Route path="/registration">
                    <Register onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/hu-HU/registration">
                    <Register onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/en-US/registration">
                    <Register onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/signin">
                    <Login onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/hu-HU/signin">
                    <Login onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/en-US/signin">
                    <Login onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/profile">
                    <Profile
                        onNavbarChange={changeNavigationBarModeTo}
                        saveProfileState={saveProfileState}
                        setSaveProfileState={setSaveProfileState}
                    />
                </Route>
                <Route path="/hu-HU/profile">
                    <Profile
                        onNavbarChange={changeNavigationBarModeTo}
                        saveProfileState={saveProfileState}
                        setSaveProfileState={setSaveProfileState}
                    />
                </Route>
                <Route path="/en-US/profile">
                    <Profile
                        onNavbarChange={changeNavigationBarModeTo}
                        saveProfileState={saveProfileState}
                        setSaveProfileState={setSaveProfileState}
                    />
                </Route>
                <Route path="/feature">
                    <Feature onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route path="/error">
                    <Error onNavbarChange={changeNavigationBarModeTo} />
                </Route>
                <Route exact path="/:problemTemplateListId">
                    <StudentJourneyProvider
                        changeNavigationBarModeTo={changeNavigationBarModeTo}
                        onProblemListIdChanged={problemListIdChanged}
                        setCanClickStudentSubmit={setCanClickStudentSubmit}
                    />
                </Route>
                <Route exact path="/hu-HU/:problemTemplateListId">
                    <StudentJourneyProvider
                        changeNavigationBarModeTo={changeNavigationBarModeTo}
                        onProblemListIdChanged={problemListIdChanged}
                        setCanClickStudentSubmit={setCanClickStudentSubmit}
                    />
                </Route>
                <Route exact path="/en-US/:problemTemplateListId">
                    <StudentJourneyProvider
                        changeNavigationBarModeTo={changeNavigationBarModeTo}
                        onProblemListIdChanged={problemListIdChanged}
                        setCanClickStudentSubmit={setCanClickStudentSubmit}
                    />
                </Route>
                <Route exact path="/:exerciseId/:tabType">
                    <ExerciseDetailsProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                    />
                </Route>
                <Route exact path="/hu-HU/:exerciseId/:tabType">
                    <ExerciseDetailsProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                    />
                </Route>
                <Route exact path="/en-US/:exerciseId/:tabType">
                    <ExerciseDetailsProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        handleNavbarTitleTop={handleNavbarTitleTop}
                        handleNavbarTitleBottom={handleNavbarTitleBottom}
                    />
                </Route>
                <Route exact path="/:exerciseId/submissions/:submissionId">
                    <ExerciseSubmissionProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        evaluationState={evaluationState}
                        setEvaluationState={setEvaluationState}
                        setCurrentProblemTemplateListId={setCurrentProblemTemplateListId}                        
                    />
                </Route>
                <Route exact path="/hu-HU/:exerciseId/submissions/:submissionId">
                    <ExerciseSubmissionProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        evaluationState={evaluationState}
                        setEvaluationState={setEvaluationState}
                        setCurrentProblemTemplateListId={setCurrentProblemTemplateListId}                        
                    />
                </Route>
                <Route exact path="/en-US/:exerciseId/submissions/:submissionId">
                    <ExerciseSubmissionProvider
                        onNavbarChange={changeNavigationBarModeTo}
                        evaluationState={evaluationState}
                        setEvaluationState={setEvaluationState}
                        setCurrentProblemTemplateListId={setCurrentProblemTemplateListId}                        
                    />
                </Route>
                <Route path="/">
                    <PageNotFound onNavbarChange={changeNavigationBarModeTo} />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
