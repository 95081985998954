import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import {
  Navbar,
  Nav,
  Button,
  Image,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getProblemListState } from "../../state/problemlist/selectors";

function ProfileNavbar({
  handleEvaluateProblemList
}) {
  const { t } = useTranslation();

  const problemList = useSelector(getProblemListState);

  return (
    <Navbar className="navbar-button-right" fixed="top">
      <Navbar.Brand href="/">
        <Image src={LogoWhite} className="logo" rounded />
      </Navbar.Brand>
      <Navbar.Brand>
        <Container className="nav-container">
          <Row className="nav-container-row">
            <Col>
              <span className="navbar-title-bottom">{problemList.userName ? problemList.userName : t("Navbar.Evaluate-Navbar.empty-userName")}</span>
            </Col>
          </Row>
        </Container>
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <Button
        bsPrefix="toolbar"
        className="btn navbar-button"
        onClick={() => {
          handleEvaluateProblemList()
        }}
      >
        <ClearIcon className="icon button-icon" />{" "}
        {t("Navbar.Evaluate-Navbar.close")}
      </Button>
    </Navbar>
  );
}

export default ProfileNavbar;
