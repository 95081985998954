import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Form } from "react-bootstrap";
import AddCircle from "@mui/icons-material/AddCircle";
import RemoveCircle from "@mui/icons-material/RemoveCircle";

function TaskNumber({
  taskNumber,
  handleTaskNumberChange,
  handleTaskNumberDec,
  handleTaskNumberInc,
}) {
  return (
    <Form.Group controlId="formTaskNumber">
      <RemoveCircle
        className="task-number-icon"
        sm={1}
        onClick={() => handleTaskNumberDec(-1)}
      />
      <Form.Control
        className="task-number-input"
        type="number"
        value={taskNumber}
        onChange={(e) => handleTaskNumberChange(e)}
      />
      <AddCircle
        className="task-number-icon"
        sm={1}
        onClick={() => handleTaskNumberInc(1)}
      />
    </Form.Group>
  );
}

export default TaskNumber;
