import { store } from 'react-notifications-component';
import { useTranslation } from "react-i18next";

export function showNotification(notificationMessage) {
    return store.addNotification({
        title: "",
        message: notificationMessage,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__backInRight"],
        animationOut: ["animate__animated", "animate__backOutRight"],
        dismiss: {
            duration: notificationMessage.length * 75,
            onScreen: true
        }
    });
}

export function useLanguageCode() {
    const { i18n } = useTranslation();

    var code="en-US"
    if(i18n.language==="en") code="en-US";
    if(i18n.language==="hu") code="hu-HU";
    
    return code
}

export const checkRequirement = (cookies) => {
    if (cookies['guid'] !== undefined) {
        return true
    } else {
        return false
    }
}
