import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Container } from "react-bootstrap";
import ProblemListCreationStep3TopSection from "./ProblemListCreationStep3TopSection";
import ProblemListCreationStep3CentralSection from "./ProblemListCreationStep3CentralSection";
import ProblemListCreationSettingsSection from "../ProblemListCreationSettingsSection";

/**
 * ProblemListCreationStep3Article is a container for the main (article) area of the 3rd step of the problem list creation progess.
 */
function ProblemListCreationStep3Article({
    subcategories,
    handleChange,
    templateList,
    handleRadioChange,
    handlePointChange,
    handleCheckboxChange,
    handleTimeLimitChange,
    handleExpireDateUTCChange,
    handleCreateJourneyState,
    timeLimitHour,
    handleTimeLimitHourChange,
    expireDate,
    timeLimitMinute,
    handleTimeLimitMinuteChange,
    expireHour,
    handleExpireHourChange,
    expireMinute,
    handleExpireMinuteChange,
    handleExpireDateChange
}) {
    const [tabState, setTabState] = useState(0);

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    return (
        <Container fluid>
            <ProblemListCreationStep3TopSection
                subcategories={subcategories}
                templateList={templateList}
                tabState={tabState}
                handleTabStateChange={handleTabStateChange}
                handleCreateJourneyState={handleCreateJourneyState}
            />
            {tabState === 0 ? (
                <ProblemListCreationStep3CentralSection
                    templateList={templateList}
                    handlePointChange={handlePointChange}
                    handleCreateJourneyState={handleCreateJourneyState}
                />
            ) : (
                    <ProblemListCreationSettingsSection
                        handleChange={handleChange}
                        templateList={templateList}
                        handleRadioChange={handleRadioChange}
                        handleCheckboxChange={handleCheckboxChange}
                        handleTimeLimitChange={handleTimeLimitChange}
                        handleExpireDateUTCChange={handleExpireDateUTCChange}
                        timeLimitHour={timeLimitHour}
                        handleTimeLimitHourChange={handleTimeLimitHourChange}
                        timeLimitMinute={timeLimitMinute}
                        handleTimeLimitMinuteChange={handleTimeLimitMinuteChange}
                        expireDate={expireDate}
                        expireHour={expireHour}
                        handleExpireHourChange={handleExpireHourChange}
                        expireMinute={expireMinute}
                        handleExpireMinuteChange={handleExpireMinuteChange}
                        handleExpireDateChange={handleExpireDateChange}
                    />
                )}
        </Container>
    );
}

export default ProblemListCreationStep3Article;
