import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { checkRequirement } from "../helpers";
import {
    randomProblemComponentChangeStateToLoading,
    randomProblemComponentChangeStateToSuccess,
    randomProblemComponentChangeStateToError
} from "../state/randomproblems/actions";

// Set the number of retries
axiosRetry(axios, { retries: 3 });
// Set the retry delay between requests
axiosRetry(axios, { retryDelay: 500 });
// Always retry, no matter what error we had
axiosRetry(axios, { retryCondition: (error) => { return (error.code === 418) || (error.code === 508) || isNetworkOrIdempotentRequestError; } });

/**
 * Generates a number of random problem examples of a problem template.
 * @param templateGuid The GUID of the problem template.
 * @param {number} count The number of problems to generate.
 * @param {string} language The culture/language to use. (eg. "en-US" or "hu-HU")
 * @returns The list of generated problems.
 */
export function getRandomProblems(cookies, dispatch, templateGuid, count, language) {
    if (checkRequirement(cookies)) {
        dispatch(randomProblemComponentChangeStateToLoading(templateGuid));

        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/problemtemplate/random/${templateGuid}/${count}`, {
                headers: {
                    "Accept-Language": language,
                }
            })
            .then((result) => {
                // result is an array of Problem classes
                dispatch(randomProblemComponentChangeStateToSuccess(result.data[0]));
            })
            .catch((error) => {
                dispatch(randomProblemComponentChangeStateToError(templateGuid, error.message));
                console.error(error, error.stack);
            });
    }
}
