import axios from "axios";
import { checkRequirement } from "../helpers";
import {
    initTemplatelistState,
    resetTemplatelistState,
} from "../state/templatelist/actions";
import { initErrorState } from "../state/error/actions";

export function getProblemTemplates(
    cookies,
    dispatch,
    language,
    history,
    exercisesURL,
    searchedKey
) {
    if (checkRequirement(cookies)) {
        dispatch(resetTemplatelistState());
        axios
            .get(
                `${process.env.REACT_APP_BASE_API_URL}/problemtemplate/${exercisesURL}/category,subcategory/${searchedKey}`,
                {
                    headers: {
                        "Accept-Language": language,
                    },
                }
            )
            .then((res) => {
                dispatch(initTemplatelistState(res.data));
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
