import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Col, Row, Button, Form } from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BlackCheckbox } from "../modification/Checkbox";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Link } from "react-router-dom";
import { publishProblemTemplateList } from "../../api/PublishProblemTemplateList";
import { deleteProblemTemplateList } from "../../api/DeleteProblemTemplateList";
import { useTranslation } from "react-i18next";
import { resetProblemTemplateListSummariesState } from "../../state/problemtemplatelistsummaries/actions";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLanguageCode } from "../../helpers";

function ExerciseMain({
  tabState,
  handleTabStateChange,
  exerciseId,
  problemTemplateList,
}) {
  const history = useHistory();
  const languageCode = useLanguageCode();
  const { t, i18n } = useTranslation();

  const [cookies] = useCookies(["guid"]);

  const copyToClipboard = (text) => {
    const link = `${process.env.REACT_APP_BASE_URL}${text}`;
    navigator.clipboard.writeText(link);
  };

  const dispatch = useDispatch();

  const isPublicState = {
    isPublic: !problemTemplateList.isPublic,
  };

  const handleDeleteProblemTemplateList = () => {
    dispatch(resetProblemTemplateListSummariesState);
    deleteProblemTemplateList(
      cookies,
      dispatch,
      history,
      exerciseId,
      handleAfterDelete
    );
  };

  const handleAfterDelete = () => {
    history.push(`/${languageCode}`);
  };


  return (
    <>
      <Helmet>
        <title>
          {t("Exercise-Details.Exercise-Main.title", {
            title:
              problemTemplateList.title === ""
                ? t("Exercise-Details.Exercise-Main.empty-title")
                : problemTemplateList.title,
          })}
        </title>
        <meta name="description" content={t("Exercise-Details.Exercise-Main.description",t("Global.description"))} />
        <meta name="keywords" content={t("Exercise-Details.Exercise-Main.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
      <Row className="create-row has-tabs">
        <Col className="inner-col">
          <Row className="inner-row">
            <Col sm={1}></Col>
            <Col sm={9} md={7} className="create-col-middle">
              <h3>
                {problemTemplateList.title.length === 0
                  ? t("Exercise-Details.Exercise-Main.empty-title")
                  : problemTemplateList.title}
              </h3>
              <p className="small-body-text">
                {problemTemplateList.description}
              </p>
            </Col>
            <Col sm={12} md={4}>
              <Form>
                <Form.Group controlId="problemTemplateListId">
                  <Form.Label>
                    {t("Exercise-Details.Exercise-Main.problem-link")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={`${process.env.REACT_APP_BASE_URL}${problemTemplateList.id}`}
                    readOnly
                  />
                </Form.Group>
                <Button
                  bsPrefix="toolbar"
                  className="btn link-without-redirection"
                  onClick={() => copyToClipboard(problemTemplateList.id)}
                  style={{ paddingRight: "15px" }}
                >
                  <BookmarksOutlinedIcon className="icon"></BookmarksOutlinedIcon>
                  {t("Exercise-Details.Exercise-Main.copy-link")}
                </Button>
                <FormControlLabel
                  control={
                    <BlackCheckbox
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      checked={problemTemplateList.isPublic}
                      onClick={() =>
                        publishProblemTemplateList(
                          cookies,
                          dispatch,
                          history,
                          isPublicState,
                          exerciseId
                        )
                      }
                    />
                  }
                  label={t("Exercise-Details.Exercise-Main.active-link")}
                />
              </Form>
              <Button
                bsPrefix="toolbar"
                className="btn link-without-redirection link-without-redirection-red"
                onClick={() => handleDeleteProblemTemplateList()}
              >
                <DeleteOutlinedIcon className="icon" />
                {t("Exercise-Details.Exercise-Main.delete-problem")}
              </Button>
            </Col>
          </Row>
          <Row className="inner-row">
            <Col sm={1}></Col>
            <Col sm={9} md={7}>
              <Link to={`/${languageCode}/${exerciseId}/submissions`}>
                <Button
                  bsPrefix="toolbar"
                  className={`btn toolbar-button ${
                    tabState === 0 ? "toolbar-button-active" : "btn-available"
                  }`}
                  onClick={() => handleTabStateChange(0)}
                >
                  <CheckCircleOutlineOutlinedIcon className="icon" />
                  {t("Exercise-Details.Exercise-Main.solutions")}
                </Button>
              </Link>
              <Link to={`/${languageCode}/${exerciseId}/questions`} style={{ display: "none" }}>
                <Button
                  bsPrefix="toolbar"
                  className={`btn toolbar-button ${
                    tabState === 1 ? "toolbar-button-active" : "btn-available"
                  }`}
                  onClick={() => handleTabStateChange(1)}
                >
                  <HelpOutlineIcon className="icon" />
                  {t("Exercise-Details.Exercise-Main.problems")}
                </Button>
              </Link>
              <Link to={`/${languageCode}/${exerciseId}/settings`}>
                <Button
                  bsPrefix="toolbar"
                  className={`btn toolbar-button ${
                    tabState === 2 ? "toolbar-button-active" : "btn-available"
                  }`}
                  onClick={() => handleTabStateChange(2)}
                >
                  <SettingsIcon className="icon" />
                  {t("Exercise-Details.Exercise-Main.settings")}
                </Button>
              </Link>
            </Col>
            <Col sm={12} md={4}></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ExerciseMain;
