import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import {
    Navbar,
    Nav,
    Button,
    Image,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useLanguageCode } from "../../helpers";

function SubmissionNavbar({
    navbarTitleTop,
    navbarTitleBottom,
}) {
    const { t } = useTranslation();
    const languageCode = useLanguageCode();
    const history = useHistory();

    return (
        <Navbar className="navbar-button-right" fixed="top">
            <Navbar.Brand href="/">
                <Image src={LogoWhite} className="logo" rounded />
            </Navbar.Brand>
            <Navbar.Brand>
                <Container className="nav-container">
                    <Row className="nav-container-row">
                        <Col>
                            <span className="navbar-title-top">{navbarTitleTop}</span>
                            <br></br>
                            <span className="navbar-title-bottom">{navbarTitleBottom}</span>
                        </Col>
                    </Row>
                </Container>
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <Button
                bsPrefix="toolbar"
                className="btn navbar-button"
                onClick={() => history.push(`/${languageCode}`)}
            >
                <ClearIcon className="icon button-icon" /> {t("Navbar.Submission-Navbar.close")}
            </Button>
        </Navbar>
    );
}

export default SubmissionNavbar;
