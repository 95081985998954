import React, { useCallback, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTemplatelistState } from "../../../state/templatelist/selectors";
import ContentGroupTopic from "../../../views/group/ContentGroupTopic";

/**
 * ProblemListCreationStep1CentralSection is a container for the central section of the 1st step of the problem list creation process.
 */
function ProblemListCreationStep1CentralSection({
    subject,
    handleSubjectStateChange,
    handleCategorySelectChange,
    subcategories,
    handleSearchedKeyChange,
}) {
    let _ = require("lodash");
    const templatelist = useSelector(getTemplatelistState);
    const [searchedKey, setSearchedKey] = useState("");

    const handleSearchedKeyStateChange = (e) => {
        setSearchedKey(e.target.value);
        debounceSearchedKey(subject, e.target.value);
    };

    const helperSubjectStateChange = (e) => {
        handleSubjectStateChange(e.target.value, searchedKey);
    };

    if (subject != "subject-mathematics") {
        handleSubjectStateChange("subject-mathematics", searchedKey);
    }

    const debounceSearchedKey = useCallback(
        _.debounce(
            (subject, searchedKey) => handleSearchedKeyChange(subject, searchedKey),
            1000
        ),
        []
    );

    const checkCategoryLength = (template) => {
        let number = 0;

        template.subgroups.forEach((subgroup) => {
            number += subgroup.templateGuids.length;
        });

        return number;
    };

    const { t } = useTranslation();

    console.log(process.env.REACT_APP_IS_SEARCHBAR_VISIBLE)
    console.log(process.env.REACT_APP_IS_SEARCHBAR_VISIBLE === "true")

    return (
        <Row className="create-row-bottom">
            <Col sm={1}></Col>
            <Col sm={9} md={7}>
                <h3>{t("Create.Create-1-Tasks.choose-exercises-and-topics")}</h3>

                <Row className="form-row subject-and-search-row">
                    <Col md={5} className="form-col">
                        <Form>
                            <Form.Group controlId="subject">
                                <Form.Label>{t("Create.Create-1-Tasks.subject")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={subject}
                                    onChange={(e) => helperSubjectStateChange(e)}
                                >
                                    <option value="none">{t("Create.Create-1-Tasks.choose-subject")}</option>
                                    <option value="subject-mathematics">{t("Create.Create-1-Tasks.subject-mathematics")}</option>
                                </Form.Control>
                                <div style={{ display: process.env.REACT_APP_IS_SEARCHBAR_VISIBLE === "true" ? '' : 'none' }}>
                                    <Form.Label>Keresés</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={searchedKey}
                                        onChange={(e) => handleSearchedKeyStateChange(e)}
                                    ></Form.Control>
                                </div>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {subject !== "none" ? (
                    <Row className="inner-row">
                        <Col className="inner-col">
                            {templatelist.subgroups.map((template) =>
                                checkCategoryLength(template) !== 0 ? (
                                    <ContentGroupTopic
                                        template={template}
                                        key={template.name}
                                        handleCategorySelectChange={handleCategorySelectChange}
                                        subcategories={subcategories}
                                    />
                                ) : (
                                        ""
                                    )
                            )}
                        </Col>
                    </Row>
                ) : (
                        ""
                    )}
            </Col>
            <Col sm={12} md={4}></Col>
        </Row>
    );
}

export default ProblemListCreationStep1CentralSection;
