export const PROBLEMLISTSUMMARIES_STATE_INIT =
  "PROBLEMLISTSUMMARIES_STATE_INIT";
export const PROBLEMLISTSUMMARIES_STATE_RESET =
  "PROBLEMLISTSUMMARIES_STATE_RESET";

export const initProblemListSummariesState = (element) => ({
  type: PROBLEMLISTSUMMARIES_STATE_INIT,
  payload: element,
});

export const resetProblemListSummariesState = (element) => ({
  type: PROBLEMLISTSUMMARIES_STATE_RESET,
  payload: element,
});
