import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { useLanguageCode } from "../../helpers";
import moment from "moment";
import "moment/min/locales"

function List3Exercise({ problemListSummary }) {

  const { t, i18n } = useTranslation();
  const languageCode = useLanguageCode();

  const date = moment.utc(problemListSummary.problemListSubmittedAt).local().locale(i18n.language).format("MMMM D. dddd - H:mm");

  const dateTime = moment(problemListSummary.problemListSubmittedAt).format();

  const history = useHistory();

  return (
    <Card className="list3-card">
      <Container className="list3-container">
        <Row className="list3-row">
          <Col xs={12} md={8}>
            <Row className="list3-row">
              <Col className="list3-small" style={{textTransform:'capitalize'}}>
                <time dateTime={dateTime}>{date}</time>
              </Col>
            </Row>
            <Row className="list3-row">
              <Col className="list3-normal">
                <a 
                  className="btn link-without-redirection link-without-redirection-blue"
                  onClick={() =>
                    history.push(
                      `/${languageCode}/${problemListSummary.problemTemplateListId}/submissions/${problemListSummary.problemListId}`
                    )
                  }
                >
                  {!problemListSummary.userName
                    ? t("List.List-3-Submission.empty-userName")
                    : problemListSummary.userName}
                </a>
              </Col>
            </Row>
            <Row className="list3-row">
              <Col className="list3-small">
                {problemListSummary.solutionCount}{" "}
                {t("List.List-3-Submission.handed-solution")} »{" "}
                {parseInt(problemListSummary.percentage)}%{" "}
                {t("List.List-3-Submission.correct")},{" "}
                {parseInt(problemListSummary.time / 60)}{" "}
                {t("List.List-3-Submission.minute")}{" "}
                {parseInt(problemListSummary.time % 60)}{" "}
                {t("List.List-3-Submission.second")}
              </Col>
            </Row>
          </Col>
          <Col className="list3-col-right" xs={6} md={4}>
            <Button
              bsPrefix="toolbar"
              className="btn link-without-redirection link-without-redirection-blue"
              onClick={() =>
                history.push(
                  `/${languageCode}/${problemListSummary.problemTemplateListId}/submissions/${problemListSummary.problemListId}`
                )
              }
            >
              <ArrowForwardIosIcon className="icon" />
              {t("List.List-3-Submission.view")}
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default List3Exercise;
