import {
  PROBLEMTEMPLATELIST_STATE_INIT,
  PROBLEMTEMPLATELIST_STATE_RESET,
} from "./actions";

export const initialState = {
  problemLists: [],
  lazyLoader: {},
  id: "",
  templateGuids: [],
  maxPoints: [5, 5, 5, 5, 5, 5],
  isPublic: true,
  isFinal: true,
  isDeleted: false,
  createdAt: "",
  deletedAt: null,
  authorUserGuid: "",
  author: "",
  title: "",
  description: "",
  tags: null,
  onePageOneProblem: false,
  allProblemsAreMandatory: false,
  timeLimit: 0,
  expiresAt: "",
  variablePointsPerProblem: false,
  automatedProblemEvaluation: false,
};

export const problemTemplateListStateReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  if (type === PROBLEMTEMPLATELIST_STATE_INIT) {
    const element = payload;
    return element;
  }

  if (type === PROBLEMTEMPLATELIST_STATE_RESET) {
    return initialState;
  }

  return state;
};
