import React from "react";
import { withStyles } from '@mui/styles';
import { Checkbox } from "@mui/material";
import { grey } from "@mui/material/colors";

export const BlackCheckbox = withStyles({
  root: {
    color: "#212121",
    "&$checked": {
      color: "#212121",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const GreyCheckbox = withStyles({
  root: {
    color: grey,
    "&$checked": {
      color: grey,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
