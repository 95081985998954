import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getErrorState } from "../../state/error/selectors";
import { useSelector } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function Error({ onNavbarChange }) {
  const error = useSelector(getErrorState);
  const history = useHistory();

  useEffect(() => {
    onNavbarChange("SignedIn");
  }, [onNavbarChange]);

  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Error.title",t("Global.title"))}</title>
        <meta name="description" content={t("Error.description",t("Global.description"))} />
        <meta name="keywords" content={t("Error.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
      <Container fluid>
        <div className="full-div">
          <h3>{error.status}</h3>
          <h4>
            {t("Error.server-response")}: {error.message}
          </h4>
          <Button
            bsPrefix="toolbar"
            className="btn primary-button primary-button-text block mx-auto"
            onClick={() => history.push("/")}
          >
            {t("Error.back-to-dashboard")}
          </Button>
        </div>
      </Container>
    </>
  );
}

export default Error;
