import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import { Container, Col, Row, Image, Button, Form } from "react-bootstrap";
import LogoBlue from "../../assets/Praktiko_logo.svg";
import { FormControlLabel } from "@mui/material";
import { BlackCheckbox } from "../modification/Checkbox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Register({ onNavbarChange }) {
  useEffect(() => {
    onNavbarChange("Guest");
  });

  const defaultRegistrationState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    subjects: [],
    accept: false,
  };

  const [registrationState, setRegistrationState] = useState(
    defaultRegistrationState
  );

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationState({ ...registrationState, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    let newSubject = registrationState.subjects;
    if (newSubject.includes(name)) {
      newSubject = newSubject.filter((subject) => subject !== name);
    } else {
      newSubject.push(name);
    }
    setRegistrationState({ ...registrationState, subjects: newSubject });
  };

  const handleAcceptChange = (e) => {
    let newState = registrationState.accept;
    newState = !newState;
    setRegistrationState({ ...registrationState, accept: newState });
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("Register.title",t("Global.title"))}</title>
        <meta name="description" content={t("Register.description",t("Global.description"))} />
        <meta name="keywords" content={t("Register.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
    <Container fluid>
      <Row className="dashboard-row">
        <Col sm={1} md={1}></Col>
        <Col sm={10} md={6} className="dashboard-col-middle">
          <h1>{t("Register.register-teacher")}</h1>
          <Form onSubmit={handleRegistrationSubmit}>
            <div className="bottom-border">
              <Form.Group controlId="lastName">
                <Form.Label>{t("Register.last-name")}</Form.Label>
                <Form.Control
                  name="lastName"
                  type="text"
                  placeholder="pl. Nagy"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="firstName">
                <Form.Label>{t("Register.first-name")}</Form.Label>
                <Form.Control
                  name="firstName"
                  type="text"
                  placeholder="pl. Bence"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>{t("Register.email")}</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="pl. nagy.bence@freemail.hu"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>{t("Register.password")}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="rePassword">
                <Form.Label>{t("Register.password-again")}</Form.Label>
                <Form.Control
                  type="password"
                  name="rePassword"
                  onChange={handleChange}
                />
              </Form.Group>

              <p className="large-body-text">
                {t("Register.subject-interest")}
              </p>

              <FormControlLabel
                control={
                  <BlackCheckbox
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                }
                name="maths"
                label={t("Register.subject-maths")}
                className="block-checkbox"
                onChange={handleCheckboxChange}
              />

              <FormControlLabel
                control={
                  <BlackCheckbox
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                }
                name="physics"
                label={t("Register.subject-physics")}
                className="block-checkbox"
                onChange={handleCheckboxChange}
              />

              <FormControlLabel
                control={
                  <BlackCheckbox
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                }
                name="accept"
                label={t("Register.accept-conditions")}
                className="block-checkbox"
                onChange={handleAcceptChange}
              />
            </div>
            <br></br>

            <Button
              className="primary-button primary-button-text "
              type="submit"
            >
              <CheckCircleOutlineOutlinedIcon className="icon" />
              {t("Register.registration")}
            </Button>
            <a href="/" style={{ marginLeft: "20px" }}>
              {t("Register.back")}
            </a>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col sm={12} md={4} className="dashboard-news">
          <Image src={LogoBlue} className="logo dashboard-logo" rounded />
          <p className="normal-body-text">{t("Register.details")}</p>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Register;
