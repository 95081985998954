import axios from "axios";
import { checkRequirement } from "../helpers";
import { initErrorState } from "../state/error/actions";

export function deleteProblemList(
    cookies,
    dispatch,
    history,
    problemListId,
    handleAfterDeleteProblemList
) {
    if (checkRequirement(cookies)) {
        axios
            .delete(`${process.env.REACT_APP_BASE_API_URL}/problemlist/${problemListId}`, {
                headers: {
                    "X-Authenticated-User": cookies.guid,
                },
            })
            .then((res) => {
                handleAfterDeleteProblemList();
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
