import {
  PROBLEMTEMPLATELISTSUMMARIES_STATE_INIT,
  PROBLEMTEMPLATELISTSUMMARIES_STATE_RESET,
} from "./actions";

export const initialState = [];

export const problemTemplateListSummariesStateReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;

  if (type === PROBLEMTEMPLATELISTSUMMARIES_STATE_INIT) {
    const element = payload;
    return element;
  }

  if (type === PROBLEMTEMPLATELISTSUMMARIES_STATE_RESET) {
    return initialState;
  }

  return state;
};
