import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/exercise.css";
import { Card, Row, Col, Container } from "react-bootstrap";
import TaskNumber from "../tasknumber/TaskNumber";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useTranslation } from "react-i18next";

function ProblemTemplateSetMaxPointsComponent({
    randomProblem,
    handlePointChange,
    problemNumber,
    templateList,
    index,
    handleChangeOrder,
    problemsLength,
    point,
}) {
    const findInTemplateList = templateList.templateGuids.findIndex(
        (tGuid) => tGuid === randomProblem.templateGuid
    );
    const [taskNumber, setTaskNumber] = useState(
        findInTemplateList === -1 ? 0 : templateList.maxPoints[findInTemplateList]
    );

    const minPoint = 1;
    const maxPoint = 50;
    const defaultPoint = 5;

    useEffect(() => {
        handlePointChange(randomProblem.templateGuid, taskNumber);
    }, [randomProblem.templateGuid, taskNumber]);

    const handleTaskNumberChange = (e) => {
        if (
            parseInt(e.target.value) >= minPoint &&
            parseInt(e.target.value) <= maxPoint
        ) {
            setTaskNumber(parseInt(e.target.value));
        } else if (isNaN(parseInt(e.target.value))) {
            setTaskNumber(defaultPoint);
        } else if (parseInt(e.target.value) < minPoint) {
            setTaskNumber(minPoint);
        } else if (parseInt(e.target.value) > maxPoint) {
            setTaskNumber(maxPoint);
        }
    };

    const handleTaskNumberInc = (newValue) => {
        if (taskNumber + parseInt(newValue) <= 50) {
            setTaskNumber(taskNumber + newValue);
        }
    };

    const handleTaskNumberDec = (newValue) => {
        if (taskNumber + parseInt(newValue) >= 1) {
            setTaskNumber(taskNumber + newValue);
        }
    };

    const { t } = useTranslation();

    return (
        <Card
            className={`exercise-create-card ${
                point > 0 ? "exercise-create-card-border" : ""
                }`}
        >
            {index !== 0 ? (
                <ArrowDropDownCircleOutlinedIcon
                    className="content-group-exercise-icon my-auto outer-icon-up"
                    onClick={() => handleChangeOrder(index, "up")}
                />
            ) : (
                    ""
                )}
            {index !== problemsLength - 1 ? (
                <ArrowDropDownCircleOutlinedIcon
                    className="content-group-exercise-icon my-auto outer-icon-down"
                    onClick={() => handleChangeOrder(index, "down")}
                />
            ) : (
                    ""
                )}
            <Container className="exercise-create-container">
                <Row className="exercise-create-row">
                    <Col xs={9} md={9}>
                        <Row className="exercise-create-row">
                            <Col className="exercise-create-normal strong-text exercise-border">
                                <p class="exercise-question"
                                    dangerouslySetInnerHTML={{
                                        __html: index + 1 + ". " + randomProblem.question,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="exercise-create-row">
                            <Col>
                                <p className="large-body-text">
                                    {t("Exercise.exercise-number", {
                                        exerciseNumber: problemNumber,
                                    })}
                                </p>
                            </Col>
                        </Row>
                        <Row className="exercise-create-row">
                            <Col>
                                <p>{t("Exercise.max-point")}</p>
                            </Col>
                        </Row>
                        <Row className="exercise-create-row">
                            <Col>
                                <TaskNumber
                                    taskNumber={point}
                                    handleTaskNumberChange={handleTaskNumberChange}
                                    handleTaskNumberInc={handleTaskNumberInc}
                                    handleTaskNumberDec={handleTaskNumberDec}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
}

export default ProblemTemplateSetMaxPointsComponent;
