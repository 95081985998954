import axios from "axios";
import {  checkRequirement } from "../helpers";
import {
    initProblemTemplateListState,
    resetProblemTemplateListState,
} from "../state/problemtemplatelist/actions";

import { initErrorState } from "../state/error/actions";

export function getProblemTemplateList(
    cookies,
    dispatch,
    language,
    history,
    problemTemplateListId
) {
    if (checkRequirement(cookies)) {
        dispatch(resetProblemTemplateListState());
        axios
            .get(
                `${process.env.REACT_APP_BASE_API_URL}/problemtemplatelist/${problemTemplateListId.toUpperCase()}`,
                {
                    headers: {
                        "X-Authenticated-User": cookies.guid,
                        "Accept-Language": language,
                    },
                }
            )
            .then((res) => {
                dispatch(initProblemTemplateListState(res.data));
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
