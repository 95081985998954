import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import {
    Container,
    Col,
    Row,
    Card,
    Image,
    Button,
    Spinner,
} from "react-bootstrap";
import LogoBlue from "../../assets/Praktiko_logo.svg";
import ContentGroupExercise from "../group/ContentGroupExercise";
import { getProblemTemplateListSummaries } from "../../api/GetProblemTemplateListSummaries";
import { useDispatch, useSelector } from "react-redux";
import { getProblemTemplateListSummariesState } from "../../state/problemtemplatelistsummaries/selectors";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLanguageCode } from "../../helpers";

function Dashboard({
    onNavbarChange,
    handleNavbarTitleTop,
    handleNavbarTitleBottom,
}) {
    const [cookies] = useCookies(["guid"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [spinner, setSpinner] = useState(true);
    const { t, i18n } = useTranslation();
    const language = useLanguageCode();
    const href = (window.location.pathname.includes("en-US/") || window.location.pathname.includes("hu-HU/")) ? "" : language;

    const problemTemplateListSummaries = useSelector(
        getProblemTemplateListSummariesState
    ).reverse();

    const problemTemplateListSummariesLoadComplete = () => {
        setSpinner(false);
    };

    useEffect(() => {
        onNavbarChange("SignedIn");
        if (cookies.guid !== undefined) {
            getProblemTemplateListSummaries(
                cookies,
                dispatch,
                language,
                history,
                problemTemplateListSummariesLoadComplete
            );
        }

    }, [cookies, dispatch, history, i18n.language, onNavbarChange]);

    handleNavbarTitleTop(t("Navbar.Toolbar-Navbar.dashboard-title"));
    handleNavbarTitleBottom(t("Navbar.Toolbar-Navbar.dashboard-subtitle"));

    const activeProblemTemplateLists = problemTemplateListSummaries.filter(
        (problemTemplateListSummary) => problemTemplateListSummary.isPublic
    );
    const inactiveProblemTemplateLists = problemTemplateListSummaries.filter(
        (problemTemplateListSummary) => !problemTemplateListSummary.isPublic
    );

    return (
        <>
            <Helmet>
                <title>{t("Dashboard.title",t("Global.title"))}</title>
                <meta name="description" content={t("Dashboard.description",t("Global.description"))} />
                <meta name="keywords" content={t("Dashboard.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Container fluid className="source-file-Dashboard">
                <Row className="dashboard-row">
                    <Col sm={1}></Col>

                    {activeProblemTemplateLists.length === 0 ? (
                        <Col sm={9} md={7} className="dashboard-col-middle">
                            <h1>{t("Dashboard.exercises")}</h1>
                            <Card className="dashboard-card">
                                <Container className="dashboard-container">
                                    <Row className="dashboard-row dashboard-row-middle">
                                        <Col>
                                            <Row className="dashboard-row dashboard-row-middle">
                                                {spinner ? (
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        variant="primary"
                                                        className={
                                                            "mx-auto my-auto"
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            {t("Global.loading")}
                                                        </span>
                                                    </Spinner>
                                                ) : (
                                                    <Col>
                                                        <p className="large-body-text">
                                                            {t(
                                                                "Dashboard.no-active-exercises"
                                                            )}
                                                        </p>
                                                        <p className="small-body-text">
                                                            {t(
                                                                "Dashboard.active-and-submitted-exercises"
                                                            )}
                                                        </p>
                                                        <a
                                                            className="button-link-with-no-decoration"
                                                            href={`${href}/create`}
                                                        >
                                                            <Button className="secondary-button primary-button-text">
                                                                {t(
                                                                    "Dashboard.new-exercise"
                                                                )}
                                                            </Button>
                                                        </a>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                            {inactiveProblemTemplateLists.length > 0 ? (
                            <ContentGroupExercise
                                title={t("Dashboard.inactive-exercises")}
                                problemTemplateListsNumber={`${inactiveProblemTemplateLists.length} ${t("Dashboard.exercise-count")}`}
                                problemTemplateLists={
                                    inactiveProblemTemplateLists
                                }
                                problemTemplateListSummaries={
                                    problemTemplateListSummaries
                                }
                            />
                            ) : ""}
                        </Col>
                    ) : (
                        <Col sm={9} md={7} className="dashboard-col-middle">
                            <h1>{t("Dashboard.my-exercises")}</h1>

                            <ContentGroupExercise
                                title={t("Dashboard.active-exercises")}
                                problemTemplateListsNumber={`${activeProblemTemplateLists.length} ${t("Dashboard.exercise-count")}`}
                                problemTemplateLists={
                                    activeProblemTemplateLists
                                }
                            />
                            <ContentGroupExercise
                                title={t("Dashboard.inactive-exercises")}
                                problemTemplateListsNumber={`${inactiveProblemTemplateLists.length} ${t("Dashboard.exercise-count")}`}
                                problemTemplateLists={
                                    inactiveProblemTemplateLists
                                }
                            />
                        </Col>
                    )}
                    <Col sm={12} md={4} className="dashboard-news">
                        <Image
                            src={LogoBlue}
                            className="logo dashboard-logo"
                            rounded
                        />
                        <p className="normal-body-text">
                            {t("Dashboard.news")}
                        </p>
                        <p className="normal-body-text">
                            {t("Dashboard.current-news-01")}
                        </p>
                        <p className="normal-body-text">
                            {t("Dashboard.current-news-02")}
                        </p>
                        <p className="normal-body-text">
                            {t("Dashboard.current-news-03")}
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Dashboard;
