import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Container } from "react-bootstrap";
import ExerciseSubmissionMain from "./ExerciseSubmissionMain";
import ExerciseSubmissionExercises from "./ExerciseSubmissionExercises";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProblemListState } from "../../state/problemlist/selectors";
import { getProblemList } from "../../api/GetProblemList";
import { useCookies } from "react-cookie";
import { getProblemTemplateList } from "../../api/GetProblemTemplateList";
import { getProblemTemplateListState } from "../../state/problemtemplatelist/selectors";
import { evaluateProblemList } from "../../api/EvaluateProblemList";
import { useTranslation } from "react-i18next";
import { useLanguageCode } from "../../helpers"

function ExerciseSubmissionProvider({
  onNavbarChange,
  evaluationState,
  setEvaluationState,
  setCurrentProblemTemplateListId,
}) {
  const dispatch = useDispatch();
  //dispatch(resetProbLemlistState());
  const [cookies] = useCookies(["guid"]);
  const { exerciseId, submissionId } = useParams();
  const problemList = useSelector(getProblemListState);
  const problemTemplateList = useSelector(getProblemTemplateListState);
  const { i18n } = useTranslation();
  var language = useLanguageCode();

  const evaluateListInit = {
    problems: [],
    evaluationNotes: "",
  };
  const [evaluateList, setEvaluteList] = useState(evaluateListInit);

  const history = useHistory();

  useEffect(() => {
      var code="en-US"
      if(i18n.language==="en") code="en-US";
      if(i18n.language==="hu") code="hu-HU";
      if(window.location.pathname === `/${exerciseId}/submissions/${submissionId}` ) {
          if(code==="hu-HU") window.location.assign(`/hu-HU/${exerciseId}/submissions/${submissionId}`);
          else window.location.assign(`/en-US/${exerciseId}/submissions/${submissionId}`);
      }
      if(window.location.pathname === `/${exerciseId}/submissions` ) {
        if(code==="hu-HU") window.location.assign(`/hu-HU/${exerciseId}/submissions`);
        else window.location.assign(`/en-US/${exerciseId}/submissions`);
      }

      if(window.location.href.includes("en-US")) language = "en-US";
      if(window.location.href.includes("hu-HU")) language = "hu-HU";
  },[]);

  useEffect(() => {
    onNavbarChange("Evaluate");
  }, [onNavbarChange]);

  useEffect(() => {
    
    getProblemList(cookies, dispatch, language, history, submissionId);
    getProblemTemplateList(
      cookies,
      dispatch,
      language,
      history,
      exerciseId
    );
  }, [cookies, dispatch, exerciseId, history, i18n.language, submissionId]);

  useEffect(() => {
    if (evaluationState === 1) {
      evaluateProblemList(
        cookies,
        dispatch,
        history,
        language,
        submissionId,
        evaluateList
      );
      setTimeout(() => {
        setEvaluationState(0);
        history.push(`/${problemTemplateList.id}/submissions`);
      }, 100);
    }
  }, [
    cookies,
    dispatch,
    evaluateList,
    evaluationState,
    history,
    problemTemplateList.id,
    setEvaluationState,
    submissionId,
  ]);

  useEffect(() => {
    let problemListArray = [];
    problemList.problems.forEach((problem) => {
      let newProblem = {
        guid: problem.guid,
        points: problem.points,
        status: problem.status,
        notes: problem.notes,
      };
      problemListArray.push(newProblem);
    });
    setEvaluteList({ ...evaluateList, problems: problemListArray });
  }, [problemList]);

  useEffect(() => {
    setCurrentProblemTemplateListId(problemTemplateList.id);
  }, [problemTemplateList, setCurrentProblemTemplateListId]);

  return (
    <Container fluid>
      <ExerciseSubmissionMain
        problemList={problemList}
        problemTemplateList={problemTemplateList}
        evaluateList={evaluateList}
        setEvaluteList={setEvaluteList}
      />
      <ExerciseSubmissionExercises
        problemList={problemList}
        evaluateList={evaluateList}
        setEvaluteList={setEvaluteList}
      />
    </Container>
  );
}

export default ExerciseSubmissionProvider;
