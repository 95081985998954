import axios from "axios";
import { initErrorState } from "../state/error/actions";
import { checkRequirement } from "../helpers";

export function selectSolutionStepOption(
    cookies,
    dispatch,
    language,
    history,
    problemGuid,
    solutionStepOptionGuid,
    handleNewSolutionStepOptions,
    index
) {
    if (checkRequirement(cookies)) {
        axios
            .post(
                `${process.env.REACT_APP_BASE_API_URL}/problemlist/select/${problemGuid}/${solutionStepOptionGuid}`, {
                    headers: {
                        "Accept-Language": language,
                        "X-Authenticated-User": cookies.guid,
                    }
                })
            .then((res) => {
                if (res.data.length !== 0) {
                    handleNewSolutionStepOptions(res.data, index);
                }
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
