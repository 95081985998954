import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import { Container, Col, Row, Form } from "react-bootstrap";
import { FormControlLabel } from "@mui/material";
import { BlackCheckbox } from "../modification/Checkbox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Profile({ onNavbarChange, saveProfileState, setSaveProfileState }) {
  useEffect(() => {
    onNavbarChange("Profile");
  });

  const defaultProfileState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    subjects: [],
    accept: false,
  };

  const [profileState, setProfileState] = useState(defaultProfileState);

  const handleProfileSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileState({ ...profileState, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    let newSubject = profileState.subjects;
    if (newSubject.includes(name)) {
      newSubject = newSubject.filter((subject) => subject !== name);
    } else {
      newSubject.push(name);
    }
    setProfileState({ ...profileState, subjects: newSubject });
  };

  const { t, i18n } = useTranslation();

  if (saveProfileState) {
    setTimeout(function () {
      setSaveProfileState(0);
    }, 100);
    return <Redirect to="/"></Redirect>;
  } else {}


  return (
    <>
      <Helmet>
        <title>{t("Profile.title",t("Global.title"))}</title>
        <meta name="description" content={t("Profile.description",t("Global.description"))} />
        <meta name="keywords" content={t("Profile.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
    <Container fluid>
      <Row className="dashboard-row">
        <Col sm={1} md={1}></Col>
        <Col sm={10} md={6} className="dashboard-col-middle">
          <h1>{t("Profile.profile")}</h1>
          <Form onSubmit={handleProfileSubmit}>
            <Form.Group controlId="lastName">
              <Form.Label>{t("Profile.last-name")}</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Nagy"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="firstName">
              <Form.Label>{t("Profile.first-name")}</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="Bence"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>{t("Profile.email")}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="nagy.bence@freemail.hu"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>{t("Profile.new-password")}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="rePassword">
              <Form.Label>{t("Profile.new-password-again")}</Form.Label>
              <Form.Control
                name="rePassword"
                type="password"
                onChange={handleChange}
              />
            </Form.Group>

            <p className="large-body-text">{t("Profile.subject-interest")}</p>

            <FormControlLabel
              control={
                <BlackCheckbox
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              }
              name="maths"
              label={t("Profile.subject-mathematics")}
              className="block-checkbox"
              onChange={handleCheckboxChange}
            />

            <FormControlLabel
              control={
                <BlackCheckbox
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              }
              name="physics"
              label={t("Profile.subject-physics")}
              className="block-checkbox"
              onChange={handleCheckboxChange}
            />
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col sm={12} md={4}></Col>
      </Row>
    </Container>
    </>
  );
}

export default Profile;
