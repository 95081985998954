import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Col, Row } from "react-bootstrap";
import List3Submissions from "../list/List3Submissions";
import { useTranslation } from "react-i18next";

function ExerciseDetailsSubmissions({
    problemTemplateList,
    problemListSummaries,
}) {
    const { t } = useTranslation();

    if (problemListSummaries.length > 0) {
        return (
            <Row className="create-row-bottom">
                <Col sm={1}></Col>
                <Col sm={7}>
                    <h3>{t("Exercise-Details.Exercise-Submissions.handed-problems")}</h3>

                    {
                        problemListSummaries.map((problemListSummary) => {
                            return problemListSummary.problemListSubmittedAt ? (
                                <List3Submissions
                                    problemListSummary={problemListSummary}
                                    key={problemListSummary.problemListId}
                                />
                            ) : (
                                    ""
                                );
                        })
                    }
                </Col>
            </Row>
        );
    } else {
        return (
            <Row className="create-row-bottom">
                <Col sm={1}></Col>
                <Col sm={7}>
                    <h3>{t("Exercise-Details.Exercise-Submissions.handed-problems")}</h3>

                    <span>{t("Exercise-Details.Exercise-Submissions.no-submitted-problemlists")}</span>
                </Col>
            </Row>
        );
    }
}

export default ExerciseDetailsSubmissions;
