import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import { useTranslation } from "react-i18next";
import { useLanguageCode } from "../../helpers";
import moment from "moment";
import "moment/min/locales";

function List3Exercise({ problemTemplateList }) {

  const { t, i18n } = useTranslation();
  const language = useLanguageCode();
  const href = (window.location.pathname.includes("en-US/") || window.location.pathname.includes("hu-HU/")) ? "" : language;

  const date = moment.utc(problemTemplateList.createdAt).local().locale(i18n.language).format("MMMM D. H:mm");
  const dateTime = moment(problemTemplateList.createdAt).format();

  const copyToClipBoard = (text) => {
    const link = `${process.env.REACT_APP_BASE_URL}${text}`;
    navigator.clipboard.writeText(link);
  };


  return (
    <Card className="list3-card">
      <Container className="list3-container">
        <Row className="list3-row">
          <Col xs={12} md={8}>
            <Row className="list3-row">
              <Col className="list3-small">
                {t("List.List-3-Exercise.subject-mathematics")}, {problemTemplateList.problemCount}{" "}
                {t("List.List-3-Exercise.problem")}, <time dateTime={dateTime}>{date}</time>
              </Col>
            </Row>
            <Row className="list3-row">
              <Col className="list3-normal">
                <a
                  href={`${href}/${problemTemplateList.problemTemplateListId}/submissions`}
                >
                  {problemTemplateList.title.length === 0
                    ? t("List.List-3-Exercise.empty-title")
                    : problemTemplateList.title}
                </a>
              </Col>
            </Row>
            <Row className="list3-row">
              <Col className="list3-small">
                {problemTemplateList.submittedProblemListIds.length}{" "}
                {t("List.List-3-Exercise.handed-problem")} »{" "}
                {t("List.List-3-Exercise.median")}:{" "}
                {parseInt(problemTemplateList.percentage.median)}%{" "}
                {t("List.List-3-Exercise.correct")}, ~{" "}
                {parseInt(problemTemplateList.time.average / 60)}{" "}
                {t("List.List-3-Exercise.minute")}&nbsp;
                {t("List.List-3-Exercise.solution-time")}
              </Col>
            </Row>
          </Col>
          <Col className="list3-col-right" xs={6} md={4}>
            <Button
              bsPrefix="toolbar"
              className="btn link-without-redirection link-without-redirection-blue"
              onClick={() =>
                copyToClipBoard(problemTemplateList.problemTemplateListId)
              }
            >
              <BookmarksOutlinedIcon className="icon" />
              {t("List.List-3-Exercise.copy-link")}
            </Button>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default List3Exercise;
