import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Container } from "react-bootstrap";
import ProblemListCreationStep2TopSection from "./ProblemListCreationStep2TopSection";
import ProblemListCreationStep2CentralSection from "./ProblemListCreationStep2CentralSection";
import ProblemListCreationSettingsSection from "../ProblemListCreationSettingsSection";

/**
 * ProblemListCreationStep2Article is a container for the main (article) area of the 2nd step of the problem list creation progess.
 */
function ProblemListCreationStep2Article({
    subcategories,
    templateList,
    subject,
    handleChange,
    handleRadioChange,
    createJourneyState,
    handleCheckboxChange,
    handleTimeLimitChange,
    handleExpireDateUTCChange,
    problemTemplateNumberChangeEventHandler,
    handleCreateJourneyState,
    timeLimitHour,
    handleTimeLimitHourChange,
    expireDate,
    timeLimitMinute,
    handleTimeLimitMinuteChange,
    expireHour,
    handleExpireHourChange,
    expireMinute,
    handleExpireMinuteChange,
    handleExpireDateChange,
}) {
    const [tabState, setTabState] = useState(0);

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    return (
        <article>
            <Container fluid>
                <ProblemListCreationStep2TopSection
                    tabState={tabState}
                    handleTabStateChange={handleTabStateChange}
                    templateList={templateList}
                    subcategories={subcategories}
                    subject={subject}
                    handleCreateJourneyState={handleCreateJourneyState}
                />
                {tabState === 0 ? (
                    <ProblemListCreationStep2CentralSection
                        subcategories={subcategories}
                        createJourneyState={createJourneyState}
                        problemTemplateNumberChangeEventHandler={problemTemplateNumberChangeEventHandler}
                        templateList={templateList}
                    />
                ) : (
                        <ProblemListCreationSettingsSection
                            handleChange={handleChange}
                            templateList={templateList}
                            handleRadioChange={handleRadioChange}
                            handleCheckboxChange={handleCheckboxChange}
                            handleTimeLimitChange={handleTimeLimitChange}
                            handleExpireDateUTCChange={handleExpireDateUTCChange}
                            timeLimitHour={timeLimitHour}
                            handleTimeLimitHourChange={handleTimeLimitHourChange}
                            timeLimitMinute={timeLimitMinute}
                            handleTimeLimitMinuteChange={handleTimeLimitMinuteChange}
                            expireDate={expireDate}
                            expireHour={expireHour}
                            handleExpireHourChange={handleExpireHourChange}
                            expireMinute={expireMinute}
                            handleExpireMinuteChange={handleExpireMinuteChange}
                            handleExpireDateChange={handleExpireDateChange}
                        />
                    )}
            </Container>
        </article>
    );
}

export default ProblemListCreationStep2Article;
