export const PROBLEMTEMPLATELIST_STATE_INIT = "PROBLEMTEMPLATELIST_STATE_INIT";
export const PROBLEMTEMPLATELIST_STATE_RESET =
  "PROBLEMTEMPLATELIST_STATE_RESET";

export const initProblemTemplateListState = (element) => ({
  type: PROBLEMTEMPLATELIST_STATE_INIT,
  payload: element,
});

export const resetProblemTemplateListState = (element) => ({
  type: PROBLEMTEMPLATELIST_STATE_RESET,
  payload: element,
});
