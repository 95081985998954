import React, { useCallback, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import { Navbar, Nav, Button, Image, Form, InputGroup } from "react-bootstrap";
import LogoDark from "../../assets/Praktiko_logo_head_only.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { updateProblemListMetadata } from "../../api/UpdateProblemListMetadata";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getProblemListState } from "../../state/problemlist/selectors";
import { useEffect } from "react";
import { showNotification } from '../../helpers.js'

function StudentNavbar({ 
  handleSubmitProblemList, 
  currentProblemListId,
  isButtonEnabled
}) {
  let _ = require("lodash");
  const [cookies] = useCookies(["guid"]);

  const initMetadata = {
    userName: "",
  };
  const problemList = useSelector(getProblemListState);
  const [userName, setUserName] = useState("");
  const [metadata, setMetadata] = useState(initMetadata);

  useEffect(() => {
    if (problemList.userName) {
      setUserName(problemList.userName);
    } else {
      setUserName(undefined);
    }
  }, [problemList]);

  const sendMetadata = (currentProblemListId, metadata) => {
    if (
      !problemList.submittedAt &&
      currentProblemListId !== "" &&
      userName !== undefined &&
      currentProblemListId !== undefined
    ) {
      updateProblemListMetadata(cookies, metadata, currentProblemListId);
    }
  };

  const debounceSendMetadata = useCallback(
    _.debounce(
      (currentProblemListId, metadata) =>
        sendMetadata(currentProblemListId, metadata),
      2500
    ),
    []
  );

  useEffect(() => {
    if (!problemList.submittedAt) {
      debounceSendMetadata(currentProblemListId, metadata);
    }
  }, [currentProblemListId, debounceSendMetadata, metadata, problemList]);

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setMetadata({ userName: e.target.value });    
  };

  const handleSubmitProblemListHelper = () => {
    if (isButtonEnabled)
        {
          updateProblemListMetadata(
            cookies,
            metadata,
            currentProblemListId,
            handleSubmitProblemList
          );
        } else 
        {
          var isUsernameEmpty = (userName === undefined || !userName || userName.length <= 1);

          var notificationMessage = (isUsernameEmpty ? t("Navbar.Student-Navbar.validation-name") : 
                                    t("Global.validation-error"));

          showNotification(notificationMessage);
        }
  };

  const { t } = useTranslation();

  return (
    <Navbar className="navbar-button-right navbar-student source-file-StudentNavbar" fixed="top">
      <Navbar.Brand href="/">
        <Image src={LogoDark} className="logo" rounded />
      </Navbar.Brand>
      <Form.Label htmlFor="inlineFormInputGroup" srOnly>Name</Form.Label>
      <InputGroup className="navbar-input">
        <InputGroup.Prepend className="navbar-prepend">
          <InputGroup.Text>{t("Navbar.Student-Navbar.username-label")}</InputGroup.Text>
        </InputGroup.Prepend>
        {!problemList.submittedAt ? (
          <Form.Control
            id="inlineFormInputGroup"
            className="navbar-input-field form-control"
            placeholder={t("Navbar.Student-Navbar.username-placeholder")}
            value={userName === undefined ? "" : userName}
            onChange={(e) => handleNameChange(e)}
          ></Form.Control>
        ) : (
          <Form.Control
            id="inlineFormInputGroup"
            className="navbar-input-field form-control"
            placeholder={t("Navbar.Student-Navbar.username-placeholder")}
            value={
              userName ? userName : t("Navbar.Student-Navbar.username-empty")
            }
            readOnly
          ></Form.Control>
        )}
      </InputGroup>
      <Nav className="mr-auto"></Nav>
      <Button
        style={{ display: !problemList.submittedAt ? "inline" : "none" }}
        bsPrefix="toolbar"
        className={`btn navbar-button navbar-student-button ${!isButtonEnabled ? "toolbar-button-disabled" : ""}`}
        onClick={() => {handleSubmitProblemListHelper();}}
      >
        <CheckCircleOutlineIcon className="icon button-icon" />
        {t("Navbar.Student-Navbar.submit")}
      </Button>
    </Navbar>
  );
}

export default StudentNavbar;
