import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row, Button } from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getTemplatelistState } from "../../../state/templatelist/selectors";

/**
 * ProblemListCreationStep2TopSection is a container for the top section of the 2nd step of the problem list creation process.
 */
function ProblemListCreationStep2TopSection({
    tabState,
    handleTabStateChange,
    subcategories,
    templateList,
    handleCreateJourneyState,
}) {
    const templatelist = useSelector(getTemplatelistState);

    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Create.Create-2-Main.title",t("Global.title"))}</title>
                <meta name="description" content={t("Create.Create-2-Main.description",t("Global.description"))} />
                <meta name="keywords" content={t("Create.Create-2-Main.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Row className="create-row has-tabs">
                <Col className="inner-col">
                    <Row className="inner-row">
                        <Col sm={1}></Col>
                        <Col sm={9} md={7} className="create-col-middle">
                            <h3>{templateList.title}</h3>
                            <p className="small-body-text">{templateList.description}</p>
                            <p className="large-body-text">
                                {t("Create.Create-2-Main.subject-mathematics")}
                                <Button
                                    bsPrefix="toolbar"
                                    className="btn link-button"
                                    onClick={() => handleCreateJourneyState(-1)}
                                    style={{ display: 'none' }}
                                >
                                    <EditOutlinedIcon className="icon" />
                                    {t("Create.Create-2-Main.edit")}
                                </Button>
                            </p>
                            {templatelist.subgroups.map((template) => {
                                return template.subgroups.map((templateSub) => {
                                    return subcategories.includes(templateSub.filter) ? (
                                        <p key={templateSub.filter}>
                                            <span className="">{template.name}</span> »{" "}
                                            <span className="">
                                                {templateSub.name}
                                            </span>
                                        </p>
                                    ) : (
                                            ""
                                        );
                                });
                            })}
                        </Col>
                        <Col sm={12} md={4} className="remove-exercise">
                            <a href="/" className="remove-exercise">
                                <DeleteOutlinedIcon className="icon" />
                                {t("Create.Create-2-Main.delete-exercise")}
                            </a>
                        </Col>
                    </Row>
                    <Row className="inner-row">
                        <Col sm={1}></Col>
                        <Col sm={9} md={7}>
                            <Button
                                bsPrefix="toolbar"
                                className={`btn toolbar-button ${
                                    tabState === 0 ? "toolbar-button-active" : "btn-available"
                                    }`}
                                onClick={() => handleTabStateChange(0)}
                            >
                                <HelpOutlineIcon className="icon" />
                                {t("Create.Create-2-Main.exercises")}
                            </Button>
                            <Button
                                bsPrefix="toolbar"
                                className={`btn toolbar-button ${
                                    tabState === 1 ? "toolbar-button-active" : "btn-available"
                                    }`}
                                onClick={() => handleTabStateChange(1)}
                            >
                                <SettingsIcon className="icon icon-tab" />
                                {t("Create.Create-2-Main.settings")}
                            </Button>
                        </Col>
                        <Col sm={12} md={4}></Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ProblemListCreationStep2TopSection;
