import axios from "axios";
import { initErrorState } from "../state/error/actions";
import { checkRequirement } from "../helpers";

export function submitProblemList(cookies, dispatch, history, problemListId) {
    if (checkRequirement(cookies)) {
        axios
            .post(`${process.env.REACT_APP_BASE_API_URL}/problemlist/submit/${problemListId}`, [], {
                headers: {
                    "X-Authenticated-User": cookies.guid,
                },
            })
            .then((res) => {
                window.location.reload(false);
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
