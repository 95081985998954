import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BlackCheckbox } from "../modification/Checkbox";
import { useTranslation } from "react-i18next";
import { deleteProblemList } from "../../api/DeleteProblemList";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLanguageCode } from "../../helpers";

function ExerciseSubmissionMain({
  problemList,
  problemTemplateList,
  evaluateList,
  setEvaluteList,
}) {
  const languageCode = useLanguageCode()
  const [evaluationNotes, setEvaluationNotes] = useState("");

  useEffect(() => {
    setEvaluationNotes(
      problemList.evaluationNotes ? problemList.evaluationNotes : ""
    );
  }, [problemList]);

  const handleChange = (e) => {
    let newEvalutationNotes = evaluateList.evaluationNotes;
    newEvalutationNotes = e.target.value;
    setEvaluteList({ ...evaluateList, evaluationNotes: newEvalutationNotes });
    setEvaluationNotes(e.target.value);
  };

  const copyToClipboard = (text) => {
    const link = `${process.env.REACT_APP_BASE_URL}${text}`;
    navigator.clipboard.writeText(link);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies] = useCookies(["guid"]);

  const handleAfterDeleteProblemList = () => {
    history.push(`/${problemTemplateList.id}/submissions`);
  };

  const handleDeleteProblemList = () => {
    deleteProblemList(
      cookies,
      dispatch,
      history,
      problemList.id,
      handleAfterDeleteProblemList
    );
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t("Exercise-Details.Exercise-Submission-Main.title", {
            title: !problemList.userName
              ? t("Exercise-Details.Exercise-Submission-Main.empty-userName")
              : problemList.userName,
          })}
        </title>
        <meta name="description" content={t("Exercise-Details.Exercise-Submission-Main.description",t("Global.description"))} />
        <meta name="keywords" content={t("Exercise-Details.Exercise-Submission-Main.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
      <Row className="create-row">
        <Col sm={1}></Col>
        <Col sm={9} md={7} className="create-col-middle">
          <h3>{problemTemplateList.title}</h3>
          <p className="small-body-text"></p>
          <p className="large-body-text">{t("Exercise-Details.Exercise-Submission-Main.subject-mathematics")}</p>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              {t("Exercise-Details.Exercise-Submission-Main.evaluation")}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              placeholder={t("Exercise-Details.Exercise-Submission-Main.evaluation-placeholder")}
              value={evaluationNotes}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={4}>
          <Form>
            <Form.Group controlId="problemTemplateListId">
              <Form.Label>
                {t("Exercise-Details.Exercise-Submission-Main.problem-link")}
              </Form.Label>
              <Form.Control
                type="text"
                value={`${process.env.REACT_APP_BASE_URL}${problemTemplateList.id}`}
                readOnly
              />
            </Form.Group>
            <Button
              bsPrefix="toolbar"
              className="btn link-without-redirection"
              onClick={() => copyToClipboard(problemTemplateList.id)}
            >
              <BookmarksOutlinedIcon className="icon"></BookmarksOutlinedIcon>
              {t("Exercise-Details.Exercise-Submission-Main.copy-link")}
            </Button>
            <FormControlLabel
              className="block-checkbox"
              control={
                <BlackCheckbox
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  checked={!problemList.evaluationCompletedAt === false}
                />
              }
              label={t("Exercise-Details.Exercise-Submission-Main.evaluated")}
            />
          </Form>
          <Button
            bsPrefix="toolbar"
            className="btn link-without-redirection link-without-redirection-red"
            onClick={() => handleDeleteProblemList()}
          >
            <DeleteOutlinedIcon className="icon" />
            {t(
              "Exercise-Details.Exercise-Submission-Main.delete-handed-solutions"
            )}
          </Button>
        </Col>
        <Col sm={1}></Col>
        <Col sm={9} md={7}></Col>
        <Col sm={12} md={4}></Col>
      </Row>
    </>
  );
}

export default ExerciseSubmissionMain;
