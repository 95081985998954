import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";

function PageNotFound({ onNavbarChange }) {
  useEffect(() => {
    onNavbarChange("None");
  });

  return (
    <div className="full-page">
      <h1>Error 404! Page not found</h1>
    </div>
  );
}

export default PageNotFound;
