import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import { Navbar, Nav, Button, Image } from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";
import { useLanguageCode} from "../../helpers";

function GuestNavbar() {
  const lanugage = useLanguageCode();
  const { t } = useTranslation();

  return (
    <Navbar fixed="top">
      <Navbar.Brand href="/">
        <Image src={LogoWhite} className="logo" rounded />
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/feature">{t("Navbar.Guest-Navbar.problem-lists")}</Nav.Link>
        <Nav.Link href="/feature">{t("Navbar.Guest-Navbar.subjects-and-topics")}</Nav.Link>
      </Nav>
      <Button bsPrefix="toolbar" className="btn navbar-button">
        <AddCircleOutline className="icon" />
        {t("Navbar.Guest-Navbar.new-problem-list")}
      </Button>
      <Nav className="navbar-right">
        <Nav.Link href="/signin">{t("Navbar.Guest-Navbar.login")}</Nav.Link>
        <Nav.Link>/</Nav.Link>
        <Nav.Link href="/registration">{t("Navbar.Guest-Navbar.register")}</Nav.Link>
      </Nav>
    </Navbar>
  );
}

export default GuestNavbar;
