import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/exercise.css";
import "../../../styles/create.css";
import { Card, Row, Col, Container, Badge } from "react-bootstrap";

function ProblemReadOnlyComponent({ problem, index }) {

  return (
    <Card className={`exercise-create-card`}>
      <Container className="exercise-create-container">
        <Row className="exercise-create-row">
          <Col xs={9} md={9}>
            <Row className="exercise-create-row">
              <Col className="exercise-create-normal large-body-text exercise-border">
                <p dangerouslySetInnerHTML={{__html: index+1 + ". " + problem.question }} />
              </Col>
            </Row>
            <Row className="exercise-create-row">
              <Col className="exercise-create-normal">
                {problem.solutionSteps[0].offeredSolutionStepOptions.map(
                  (solutionStepOption, index) => {
                    return (
                      <Badge
                        className={`multiple-choise-answer-view ${
                          index ===
                          problem.solutionSteps[0]
                            .selectedSolutionStepOptionIndex
                            ? "multiple-choise-answer-view-selected"
                            : ""
                        }`}
                        key={solutionStepOption.guid}
                        dangerouslySetInnerHTML={{
                          __html: solutionStepOption.presentation,
                        }}
                      />
                    );
                  }
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default ProblemReadOnlyComponent;
