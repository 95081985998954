export const RANDOMPROBLEMS_STATE_INIT = "RANDOMPROBLEMS_STATE_INIT";
export const RANDOMPROBLEMS_STATE_RESET = "RANDOMPROBLEMS_STATE_RESET";
export const RANDOMPROBLEMS_STATE_LOADING = "RANDOMPROBLEMS_STATE_LOADING";
export const RANDOMPROBLEMS_STATE_SUCCESS = "RANDOMPROBLEMS_STATE_SUCCESS";
export const RANDOMPROBLEMS_STATE_SWAPTWO = "RANDOMPROBLEMS_STATE_SWAPTWO";
export const RANDOMPROBLEMS_STATE_ERROR = "RANDOMPROBLEMS_STATE_ERROR";

export const randomProblemComponentChangeStateToReset = () => ({
    type: RANDOMPROBLEMS_STATE_RESET
});

export const randomProblemComponentChangeStateToError = (problemTemplateGuid, error) => ({
    type: RANDOMPROBLEMS_STATE_ERROR,
    payload: [problemTemplateGuid, error],
})

export const randomProblemComponentChangeStateToLoading = (problemTemplateGuid) => ({
    type: RANDOMPROBLEMS_STATE_LOADING,
    payload: problemTemplateGuid,
})

export const randomProblemComponentChangeStateToSuccess = (problem) => ({
    type: RANDOMPROBLEMS_STATE_SUCCESS,
    payload: problem,
})

export const randomProblemComponentChangeStateToSwapTwo = (indexes) => ({
    type: RANDOMPROBLEMS_STATE_SWAPTWO,
    payload: indexes,
})