import { NAVBAR_STATE_CHANGE } from "./actions";

const initialState = "Guest";

export const navbarStateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === NAVBAR_STATE_CHANGE) {
    const element = payload;
    return element;
  }

  return state;
};
