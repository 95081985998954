import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import GuestNavbar from "./GuestNavbar";
import SignedInNavbar from "./SignedInNavbar";
import ToolbarNavbar from "./ToolbarNavbar";
import StudentNavbar from "./StudentNavbar";
import ProfileNavbar from "./ProfileNavbar";
import EvaluateNavbar from "./EvaluateNavbar";
import SubmissionNavbar from "./SubmissionNavbar";

function NavbarProvider({
    navbarState,
    onCreateJourneyChange,
    navbarTitleTop,
    navbarTitleBottom,
    handleSaveProfile,
    handleSubmitProblemList,
    handleEvaluateProblemList,
    currentProblemListId,
    currentProblemTemplateListId,
    canClickOnNextCreate,
    createJourneyState,
    handleCreateProblemTemplateList,
    canClickStudentSubmit
}) {
    switch (navbarState) {
        case "Guest":
            return <GuestNavbar />;
        case "SignedIn":
            return (
                <SignedInNavbar
                    navbarTitleTop={navbarTitleTop}
                    navbarTitleBottom={navbarTitleBottom}
                />
            );
        case "Toolbar":
            return (
                <ToolbarNavbar
                    onCreateJourneyChange={onCreateJourneyChange}
                    navbarTitleTop={navbarTitleTop}
                    navbarTitleBottom={navbarTitleBottom}
                    isButtonEnabled={canClickOnNextCreate}
                    createJourneyState={createJourneyState}
                    handleCreateProblemTemplateList={handleCreateProblemTemplateList}
                />
            );
        case "Student":
            return (
                <StudentNavbar
                    handleSubmitProblemList={handleSubmitProblemList}
                    currentProblemListId={currentProblemListId}
                    isButtonEnabled={canClickStudentSubmit}
                />
            );
        case "Profile":
            return <ProfileNavbar handleSaveProfile={handleSaveProfile} />;
        case "Evaluate":
            return (
                <EvaluateNavbar
                    handleEvaluateProblemList={handleEvaluateProblemList}
                    currentProblemTemplateListId={currentProblemTemplateListId}
                />
            );
        case "Submission":
            return <SubmissionNavbar
                    navbarTitleTop={navbarTitleTop}
                    navbarTitleBottom={navbarTitleBottom}
                />;
        default:
            return "";
    }
}
export default NavbarProvider;
