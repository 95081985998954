export const ERROR_STATE_INIT =
  "ERROR_STATE_INIT";
export const ERROR_STATE_RESET =
  "ERROR_STATE_RESET";
  
export const initErrorState = (element) => ({
  type: ERROR_STATE_INIT,
  payload: element,
});

export const resetErrorState = (element) => ({
  type: ERROR_STATE_RESET,
  payload: element,
});
