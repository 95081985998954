import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import "../../../styles/create.css";
import { Col, Row, Button } from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getRandomProblemsState } from "../../../state/randomproblems/selectors";
import { randomProblemComponentChangeStateToSwapTwo } from "../../../state/randomproblems/actions";
import ProblemTemplateSetMaxPointsComponent from "../../../views/exercise/ProblemTemplateSetMaxPointsComponent";

/**
 * ProblemListCreationStep3CentralSection is a container for the central section of the 3rd step of the problem list creation process.
 */
function ProblemListCreationStep3CentralSection({
    templateList,
    handlePointChange,
    handleCreateJourneyState,
}) {
    const randomProblems = useSelector(getRandomProblemsState);

    const filteredRandomProblems = randomProblems.filter((randomProblem) => {
        return templateList.templateGuids.includes(randomProblem.templateGuid);
    });

    const countNumberOfSameProblem = (problemTemplateGuid) => {
        let number = 0;
        templateList.templateGuids.forEach((templateGuid) => {
            if (templateGuid === problemTemplateGuid) {
                number++;
            }
        });
        return number;
    };

    const dispatch = useDispatch();

    const handleChangeOrder = (index, direction) => {
        if (direction === "up") {
            dispatch(
                randomProblemComponentChangeStateToSwapTwo({
                    firstGuid: filteredRandomProblems[index - 1].templateGuid,
                    secondGuid: filteredRandomProblems[index].templateGuid,
                })
            );
        } else if (direction === "down") {
            dispatch(
                randomProblemComponentChangeStateToSwapTwo({
                    firstGuid: filteredRandomProblems[index].templateGuid,
                    secondGuid: filteredRandomProblems[index + 1].templateGuid,
                })
            );
        }
    };

    const { t } = useTranslation();

    return (
        <Row className="create-row-bottom">
            <Col sm={1}></Col>
            <Col sm={10}>
                <h3>
                    {t("Create.Create-3-Tasks.exercises")}
                    <Button
                        bsPrefix="toolbar"
                        className="btn link-button"
                        onClick={() => handleCreateJourneyState(-1)}
                    >
                        <EditOutlinedIcon className="icon" />
                        {t("Create.Create-3-Tasks.edit")}
                    </Button>
                </h3>
                {filteredRandomProblems.map((randomProblem, index) => {
                    return (
                        <ProblemTemplateSetMaxPointsComponent
                            key={randomProblem.guid}
                            randomProblem={randomProblem}
                            handlePointChange={handlePointChange}
                            problemNumber={countNumberOfSameProblem(
                                randomProblem.templateGuid
                            )}
                            templateList={templateList}
                            index={index}
                            handleChangeOrder={handleChangeOrder}
                            problemsLength={filteredRandomProblems.length}
                            point={
                                templateList.templateGuids.findIndex(
                                    (tGuid) => tGuid === randomProblem.templateGuid
                                ) !== -1
                                    ? templateList.maxPoints[
                                    templateList.templateGuids.findIndex(
                                        (tGuid) => tGuid === randomProblem.templateGuid
                                    )
                                    ]
                                    : 0
                            }
                        />
                    );
                })}
            </Col>
        </Row>
    );
}

export default ProblemListCreationStep3CentralSection;
