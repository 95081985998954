import axios from "axios";
import { checkRequirement } from "../helpers";

export function updateProblemListMetadata(
    cookies,
    metadata,
    problemListId,
    handleSubmitProblemList
) {
    if (checkRequirement(cookies)) {
        axios
            .patch(
                `${process.env.REACT_APP_BASE_API_URL}/problemlist/metadata/${problemListId}`,
                metadata,
                {
                    headers: {
                        "X-Authenticated-User": cookies.guid,
                    },
                }
            )
            .then((res) => {
                handleSubmitProblemList();
            })
            .catch(() => {});
    }
}
