import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { checkRequirement } from "../helpers";
import {
    studentProblemSetComponentChangeStateToLoading,
    studentProblemSetComponentChangeStateToSuccess
} from "../state/problemlist/actions";
import {
    initErrorState
} from "../state/error/actions";

// Set the number of retries
axiosRetry(axios, { retries: 3 });
// Set the retry delay between requests
axiosRetry(axios, { retryDelay: 500 });
// Always retry, no matter what error we had
axiosRetry(axios, { retryCondition: (error) => { return (error.code === 418) || (error.code === 508) || isNetworkOrIdempotentRequestError; } });

/**
 * Generates a new problem list based on a problem template list and a hash value.
 * @param {string} problemTemplateListId ID of the problem template list
 * @param {string} hash Hash string to use for the problem parameter generation
 * @param {string} language The culture/language to use. (eg. "en-US" or "hu-HU")
 * @returns The list of generated problems.
 */
export function generateProblemList(
    cookies,
    dispatch,
    history,
    problemTemplateListId,
    hash,
    language,
    onSuccess
) {
    if (checkRequirement(cookies)) {
        //dispatch(studentProblemSetComponentChangeStateToLoading(problemTemplateListId));

        axios
            .post(
                `${process.env.REACT_APP_BASE_API_URL}/problemlist/${problemTemplateListId.toUpperCase()}/${hash}`,
                [],
                {
                    headers: {
                        "Accept-Language": language,
                        "X-Authenticated-User": cookies.guid,
                    },
                }
            )
            .then((result) => {
                // result is a ProblemList class
                dispatch(studentProblemSetComponentChangeStateToSuccess(result.data));
                onSuccess(result.data);
            })
            .catch((error) => {
                dispatch(initErrorState(error.response));
                console.error(error, error.stack);
                history.push("/error");
            });
    }
}
