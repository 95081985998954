import axios from "axios";
import { checkRequirement } from "../helpers";

export function getSolutionStepOptions(
    cookies,
    language,
    problem,
    solutionStep,
    onSuccess
) {
    if (checkRequirement(cookies)) {
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/problemlist/${problem.guid}/${solutionStep}`, {
                    headers: {
                        "Accept-Language": language,
                        "X-Authenticated-User": cookies.guid,
                    },
                })
            .then((solutionStepOptions) => {
                // solutionStepOptions.data is an array of SolutionStepOption objects
                onSuccess(problem, solutionStep, solutionStepOptions.data);
            })
            .catch(() => {});
    }
}
