export const TEMPLATELIST_STATE_INIT = "TEMPLATELIST_STATE_INIT";
export const TEMPLATELIST_STATE_RESET = "TEMPLATELIST_STATE_RESET";

export const initTemplatelistState = (element) => ({
  type: TEMPLATELIST_STATE_INIT,
  payload: element,
});

export const resetTemplatelistState = (element) => ({
  type: TEMPLATELIST_STATE_RESET,
  payload: element,
});
