import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import ProblemToSolveComponent from "../exercise/quiz/ProblemToSolveComponent";
import ProblemReadOnlyComponent from "../exercise/quiz/ProblemReadOnlyComponent";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/min/locales";

function StudentProblemListToSolveComponent({
    problemList, state
}) {
    const { t, i18n } = useTranslation();

    const expirationTimeISO = moment(problemList.expiresAt).format();

    const expirationTime = problemList.expiresAt
        ? moment
            .utc(problemList.expiresAt)
            .local()
            .locale(i18n.language)
            .format("YYYY. MMMM D. H:mm")
        : "-";


    useEffect(() => {
        if (problemList.problems.length !== 0) {
            problemList.problems.map((problem, index) => {
                var uniqueKey = problem.guid + "-" + index;
                problem.key = uniqueKey;
            })
        }
    });

    return (
        <>
            <Helmet>
                <title>
                    {t("Exercise-Details.Exercise-Main.title", {
                        title:
                            problemList.title === ""
                                ? t("Exercise-Details.Exercise-Main.empty-title")
                                : problemList.title,
                    })}
                </title>
                <meta name="description" content={t("Exercise-Details.Exercise-Main.description",t("Global.description"))} />
                <meta name="keywords" content={t("Exercise-Details.Exercise-Main.keywords",t("Global.keywords"))} />
                <meta http-equiv="Content-Language" content={i18n.language} />
                <html lang={i18n.language}/>
            </Helmet>
            <Container fluid className="source-file-ProblemListSolutionComponent">
                <Row className="create-row">
                    <Col sm={1}></Col>
                    <Col sm={9} md={7}>
                        <h3>{problemList.title === "" ? t("Exercise-Details.Exercise-Main.empty-title") : problemList.title}</h3>
                        <p className="small-body-text">{problemList.description}</p>
                    </Col>
                    <Col sm={12} md={4}>
                        <p className="small-body-text">{t("Exercise-Details.Exercise-Main.expiration-time")}</p>
                        {problemList.expiresAt ? (
                            <time dateTime={expirationTimeISO}>
                                <p className="large-body-text">{expirationTime}</p>
                            </time>
                        ) : (
                            <p className="large-body-text">{expirationTime}</p>
                        )}
                    </Col>
                </Row>
                {
                    problemList.problems.length === 0 ? (
                        <Row className="create-row-bottom">
                            <Spinner
                                animation="border"
                                role="status"
                                variant="primary"
                                className={"mx-auto my-auto"}
                            >
                            <span className="sr-only">{t("Global.loading")}</span>
                            </Spinner>
                        </Row>

                    ) : (
                        <Row className="create-row-bottom" >
                            <Col sm={1}></Col>
                            <Col sm={10}>
                                {problemList.submittedAt === null
                                    ? problemList.problems.map((problem, index) => {
                                            return (
                                                <ProblemToSolveComponent
                                                    key={problem.key}
                                                    problem={problem}
                                                    problemListId={problemList.id}
                                                    index={index}
                                                    problemState={problemList.problems[index]} 
                                                />
                                            );
                                    })
                                    : problemList.problems.map((problem, index) => {
                                        return (
                                            <ProblemReadOnlyComponent
                                                key={problem.guid}
                                                problem={problem}
                                                id={problemList.id}
                                                index={index}
                                            />
                                        );
                                    })}
                            </Col>
                        </Row>
                    )
                }

            </Container>
        </>
    );
}

export default StudentProblemListToSolveComponent;
