import React from "react";
import { withStyles } from '@mui/styles';
import Radio from "@mui/material/Radio";

export const BlackRadio = withStyles({
  root: {
    color: "#373A3C",
    "&$checked": {
      color: "#373A3C",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
