import {
  CREATETEMPLATE_STATE_INIT,
  CREATETEMPLATE_STATE_RESET,
} from "./actions";

const initialState = {
  id: "",
  templateGuids: [],
  maxPoints: [],
  isPublic: " ",
  isFinal: true,
  createdAt: "",
  author: "",
  title: "",
  description: "",
  tags: [],
  onePageOneProblem: true,
  allProblemsAreMandatory: true,
  timeLimit: 0,
  expiresAt: "",
  variablePointsPerProblem: true,
  automatedProblemEvaluation: true,
};
export const createTemplateStateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === CREATETEMPLATE_STATE_INIT) {
    const element = payload;
    if (state[0].id === "") {
      return element;
    } else {
      return [element];
    }
  }

  if (type === CREATETEMPLATE_STATE_RESET) {
    return initialState;
  }

  return state;
};
