import axios from "axios";
import { checkRequirement } from "../helpers";
import { initErrorState } from "../state/error/actions";

export function updateProblemTemplateList(
    cookies,
    dispatch,
    history,
    templateList
) {
    if (checkRequirement(cookies)) {
        axios
            .put(
                `${process.env.REACT_APP_BASE_API_URL}/problemtemplatelist/${templateList.id}`,
                templateList,
                {
                    headers: {
                        "X-Authenticated-User": cookies.guid,
                    },
                }
            )
            .then((res) => {})
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
