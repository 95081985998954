import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import { Col, Row, Form } from "react-bootstrap";
import { FormControlLabel } from "@mui/material";
import { BlackCheckbox } from "../modification/Checkbox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { BlackRadio } from "../modification/Radio";
import { useTranslation } from "react-i18next";
import { updateProblemTemplateList } from "../../api/UpdateProblemTemplateList";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function ExerciseSettings({ problemTemplateList }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timeLimitHour, setTimeLimitHour] = useState(0);
    const [timeLimitMinute, setTimeLimitMinute] = useState(0);
    const [expireDate, setExpireDate] = useState("");
    const [expireHour, setExpireHour] = useState(0);
    const [expireMinute, setExpireMinute] = useState(0);
    const [newDate, setNewDate] = useState();
    const [firstLoad, setFirstLoad] = useState(true);
    const [currentProblemTemplateList, setCurrentProblemTemplateList] = useState(
        problemTemplateList
    );
    const [cookies] = useCookies(["guid"]);
    let _ = require("lodash");

    useEffect(() => {
        setCurrentProblemTemplateList(problemTemplateList);
    }, [problemTemplateList]);

    const handleRadioChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            value === "true"
                ? setCurrentProblemTemplateList({
                    ...currentProblemTemplateList,
                    [name]: true,
                })
                : setCurrentProblemTemplateList({
                    ...currentProblemTemplateList,
                    [name]: false,
                });
        },
        [currentProblemTemplateList]
    );

    const handleCheckboxChange = useCallback(
        (e) => {
            const { name } = e.target;
            currentProblemTemplateList[name]
                ? setCurrentProblemTemplateList({
                    ...currentProblemTemplateList,
                    [name]: false,
                })
                : setCurrentProblemTemplateList({
                    ...currentProblemTemplateList,
                    [name]: true,
                });
        },
        [currentProblemTemplateList]
    );

    const handleChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            setCurrentProblemTemplateList({
                ...currentProblemTemplateList,
                [name]: value,
            });
        },
        [currentProblemTemplateList]
    );

    const handleExpireDateChange = useCallback(
        (newExpireDate) => {
            setCurrentProblemTemplateList({
                ...currentProblemTemplateList,
                expiresAt: newExpireDate,
            });
        },
        [currentProblemTemplateList]
    );

    function parseISOString(s) {
        var b = s.split(/\D+/);
        let newDate = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
        return newDate;
    }

    const handleTimeLimitChange = useCallback(
        (e) => {
            const { name, value } = e.target;
            if (name === "timeLimitMinute") {
                if (parseInt(value) >= 0 && parseInt(value) <= 59) {
                    setTimeLimitMinute(value);
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: timeLimitHour * 3600 + value * 60,
                    });
                } else if (parseInt(value) >= 59) {
                    setTimeLimitMinute(59);
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: timeLimitHour * 3600 + 59 * 60,
                    });
                } else if (value === "") {
                    setTimeLimitMinute("");
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: timeLimitHour * 3600 + 0 * 60,
                    });
                }
            } else if (name === "timeLimitHour") {
                if (parseInt(value) >= 0 && parseInt(value) <= 9) {
                    setTimeLimitHour(value);
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: value * 3600 + timeLimitMinute * 60,
                    });
                } else if (parseInt(value) > 9) {
                    setTimeLimitHour(9);
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: 9 * 3600 + timeLimitMinute * 60,
                    });
                } else if (value === "") {
                    setTimeLimitHour("");
                    setCurrentProblemTemplateList({
                        ...currentProblemTemplateList,
                        timeLimit: 0 * 3600 + timeLimitMinute * 60,
                    });
                }
            }
        },
        [currentProblemTemplateList, timeLimitHour, timeLimitMinute]
    );

    const updateProblemTemplateListHelper = useCallback(
        (currentProblemTemplateList) => {
            updateProblemTemplateList(
                cookies,
                dispatch,
                history,
                currentProblemTemplateList
            );
        },
        [cookies, dispatch, history]
    );

    const debounceUpdateProblemTemplateList = useCallback(
        _.debounce(
            (currentProblemTemplateList) =>
                updateProblemTemplateListHelper(currentProblemTemplateList),
            2000
        ),
        []
    );

    const handleExpireHour = (newExpireHour) => {
        if (parseInt(newExpireHour) <= 23 && parseInt(newExpireHour) >= 0) {
            setExpireHour(newExpireHour);
        } else if (parseInt(newExpireHour) > 23) {
            setExpireHour(23);
        } else if (parseInt(newExpireHour) < 0) {
            setExpireHour(0);
        } else if (newExpireHour === "") {
            setExpireHour("");
        }
    };

    const handleExpireMinute = (newExpireMinute) => {
        if (parseInt(newExpireMinute) <= 59 && parseInt(newExpireMinute) >= 0) {
            setExpireMinute(newExpireMinute);
        } else if (parseInt(newExpireMinute) > 59) {
            setExpireMinute(59);
        } else if (parseInt(newExpireMinute) < 0) {
            setExpireMinute(0);
        } else if (newExpireMinute === "") {
            setExpireMinute("");
        }
    };

    useEffect(() => {
        debounceUpdateProblemTemplateList(currentProblemTemplateList);
        if (currentProblemTemplateList.id !== "" && firstLoad) {
            if (problemTemplateList.expiresAt !== null) {
                setNewDate(parseISOString(currentProblemTemplateList.expiresAt));
            }
            setTimeLimitHour(parseInt(currentProblemTemplateList.timeLimit / 3600));
            setTimeLimitMinute(
                (currentProblemTemplateList.timeLimit -
                    parseInt(currentProblemTemplateList.timeLimit / 3600) * 3600) /
                60
            );
            setFirstLoad(false);
        }
    }, [
        currentProblemTemplateList,
        debounceUpdateProblemTemplateList,
        firstLoad,
    ]);

    useEffect(() => {
        if (newDate !== undefined) {
            const newMonth =
                newDate.getMonth() + 1 < 10
                    ? "0" + parseInt(newDate.getMonth() + 1)
                    : newDate.getMonth() + 1;
            const newDay =
                newDate.getDate() < 10
                    ? "0" + parseInt(newDate.getDate())
                    : newDate.getDate();
            setExpireDate(newDate.getFullYear() + "-" + newMonth + "-" + newDay);
            setExpireHour(newDate.getHours());
            setExpireMinute(newDate.getMinutes());
        }
    }, [newDate]);

    useEffect(() => {
        if (!firstLoad) {
            let newDate;
            if (expireDate !== "") {
                newDate = new Date(
                    expireDate.split("-")[0],
                    expireDate.split("-")[1] - 1,
                    expireDate.split("-")[2],
                    expireHour,
                    expireMinute,
                    0
                ).toISOString();
                handleExpireDateChange(newDate);
            }
        }
    }, [expireDate, expireHour, expireMinute, firstLoad]);

    const { t } = useTranslation();

    return (
        <Row className="dashboard-row">
            <Col sm={1} md={1}></Col>
            <Col sm={10} className="dashboard-col-middle">
                <h2>{t("Exercise.Settings.settings")}</h2>
                <Form>
                    <Row className="inner-row">
                        <Col className="inner-col" sm={6}>
                            <Form.Group controlId="title">
                                <Form.Label>{t("Exercise.Settings.exercise-title")}</Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    value={currentProblemTemplateList.title}
                                    placeholder={t("Create.Settings.exercise-title-placeholder")}
                                    onChange={(e) => handleChange(e)}
                                    maxLength="70"
                                />
                            </Form.Group>

                            <Form.Group controlId="details">
                                <Form.Label>
                                    {t("Create.Settings.exercise-description")}
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    as="textarea"
                                    rows="3"
                                    value={currentProblemTemplateList.description}
                                    placeholder={t("Create.Settings.exercise-description-placeholder")}
                                    onChange={(e) => handleChange(e)}
                                    maxLength="1000"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ display: "none" }}>
                        <h4 className="settings-header">
                            {t("Create.Settings.visualization")}
                        </h4>

                        <BlackRadio
                            onChange={(e) => handleRadioChange(e)}
                            checked={currentProblemTemplateList.onePageOneProblem === false}
                            value="false"
                            name="onePageOneProblem"
                            inputProps={{ "aria-label": "0" }}
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                            disabled={problemTemplateList.isFinal}
                        />
                        <span style={{ color: problemTemplateList.isFinal ? "grey" : "" }}>
                            {t("Create.Settings.all-exercise-in-one-page")}
                        </span>
                        <BlackRadio
                            onChange={(e) => handleRadioChange(e)}
                            checked={currentProblemTemplateList.onePageOneProblem === true}
                            value="true"
                            name="onePageOneProblem"
                            inputProps={{ "aria-label": "1" }}
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                            disabled={problemTemplateList.isFinal}
                        />
                        <span style={{ color: problemTemplateList.isFinal ? "grey" : "" }}>
                            {t("Create.Settings.one-page-one-exercise")}
                        </span>
                    </div>

                    <h4 className="settings-header">
                        {t("Create.Settings.restrictions")}
                    </h4>

                    <div style={{ display: "none" }}>
                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={
                                        problemTemplateList.isFinal ? (
                                            <CheckBoxOutlinedIcon style={{ color: "grey" }} />
                                        ) : (
                                                <CheckBoxOutlinedIcon />
                                            )
                                    }
                                />
                            }
                            onChange={(e) => handleCheckboxChange(e)}
                            checked={
                                currentProblemTemplateList.allProblemsAreMandatory === true
                            }
                            name="allProblemsAreMandatory"
                            label={t("Create.Settings.all-problems-are-mandatory")}
                            className="block-checkbox"
                            disabled={problemTemplateList.isFinal}
                        />
                    </div>

                    <FormControlLabel
                        control={
                            <BlackCheckbox
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={<CheckBoxOutlinedIcon />}
                            />
                        }
                        checked={
                            (parseInt(timeLimitHour) !== 0 && timeLimitHour !== "") ||
                            (parseInt(timeLimitMinute) !== 0 && timeLimitMinute !== "")
                        }
                        label={t("Create.Settings.time-limit-title")}
                        className="block-checkbox"
                        disabled={problemTemplateList.isFinal}
                    />

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                        >
                            {t("Create.Settings.hour")}
                        </Form.Label>
                        <Form.Control
                            value={timeLimitHour}
                            onChange={handleTimeLimitChange}
                            type="number"
                            name="timeLimitHour"
                            placeholder="0"
                            style={{ width: "50px" }}
                            disabled={problemTemplateList.isFinal}
                            maxLength="3"
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="title"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                        >
                            {t("Create.Settings.minute")}
                        </Form.Label>
                        <Form.Control
                            value={timeLimitMinute}
                            onChange={handleTimeLimitChange}
                            name="timeLimitMinute"
                            type="number"
                            placeholder="0"
                            style={{ width: "50px" }}
                            disabled={problemTemplateList.isFinal}
                        />
                    </Form.Group>

                    <FormControlLabel
                        control={
                            <BlackCheckbox
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={
                                    problemTemplateList.isFinal ? (
                                        <CheckBoxOutlinedIcon style={{ color: "grey" }} />
                                    ) : (
                                            <CheckBoxOutlinedIcon />
                                        )
                                }
                            />
                        }
                        checked={
                            expireDate !== "" ||
                            (parseInt(expireHour) !== 0 && expireHour !== "") ||
                            (parseInt(expireMinute) !== 0 && expireMinute !== "")
                        }
                        label={t("Create.Settings.inactive-link")}
                        className="block-checkbox"
                        disabled={problemTemplateList.isFinal}
                    />

                    <Form.Group
                        controlId="expireDate"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                        >
                            {t("Create.Settings.date")}
                        </Form.Label>
                        <Form.Control
                            className="date-input"
                            value={expireDate}
                            type="date"
                            onChange={(e) => setExpireDate(e.target.value)}
                            placeholder="NN/HH/EEEE"
                            style={{ width: "200px" }}
                            disabled={problemTemplateList.isFinal}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="expireHour"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                        >
                            {t("Create.Settings.hour")}
                        </Form.Label>
                        <Form.Control
                            value={expireHour}
                            onChange={(e) => handleExpireHour(e.target.value)}
                            type="number"
                            placeholder="0"
                            maxLength="1"
                            style={{ width: "50px" }}
                            disabled={problemTemplateList.isFinal}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="expireMinute"
                        className="inner-element"
                        style={{ display: "inline-block" }}
                    >
                        <Form.Label
                            style={{ color: problemTemplateList.isFinal ? "grey" : "" }}
                        >
                            {t("Create.Settings.minute")}
                        </Form.Label>
                        <Form.Control
                            value={expireMinute}
                            onChange={(e) => handleExpireMinute(e.target.value)}
                            type="number"
                            placeholder="00"
                            maxLength="2"
                            style={{ width: "50px" }}
                            disabled={problemTemplateList.isFinal}
                        />
                    </Form.Group>
                    <span className="inner-element">CET</span>

                    <div style={{ display: "none" }}>
                        <h4 className="settings-header">
                            {t("Create.Settings.evaluation")}
                        </h4>

                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={
                                        problemTemplateList.isFinal ? (
                                            <CheckBoxOutlinedIcon style={{ color: "grey" }} />
                                        ) : (
                                                <CheckBoxOutlinedIcon />
                                            )
                                    }
                                />
                            }
                            name="variablePointsPerProblem"
                            onChange={(e) => handleCheckboxChange(e)}
                            checked={
                                currentProblemTemplateList.variablePointsPerProblem === true
                            }
                            label={t("Create.Settings.exercises-worth-point")}
                            className="block-checkbox"
                            disabled={problemTemplateList.isFinal}
                        />

                        <FormControlLabel
                            control={
                                <BlackCheckbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={
                                        problemTemplateList.isFinal ? (
                                            <CheckBoxOutlinedIcon style={{ color: "grey" }} />
                                        ) : (
                                                <CheckBoxOutlinedIcon />
                                            )
                                    }
                                />
                            }
                            name="automatedProblemEvaluation"
                            onChange={(e) => handleCheckboxChange(e)}
                            checked={
                                currentProblemTemplateList.automatedProblemEvaluation === true
                            }
                            label="Automatikus ellenorzes"
                            className="block-checkbox"
                            disabled={problemTemplateList.isFinal}
                        />
                    </div>
                </Form>
            </Col>
        </Row>
    );
}

export default ExerciseSettings;
