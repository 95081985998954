import axios from "axios";
import { checkRequirement } from "../helpers";
import { studentProblemSetComponentChangeStateToInit } from "../state/problemlist/actions";
import { initErrorState } from "../state/error/actions";

export function getProblemList(
    cookies,
    dispatch,
    language,
    history,
    problemListId,
    onSuccess
) {
    if (checkRequirement(cookies)) {
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/problemlist/${problemListId}`, {
                headers: {
                    "Accept-Language": language,
                    "X-Authenticated-User": cookies.guid,
                },
            })
            .then((res) => {
                dispatch(studentProblemSetComponentChangeStateToInit(res.data));
                //onSuccess(res.data);
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
