import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/exercise.css";
import "../../styles/create.css";
import { Card, Row, Col, Container, Form } from "react-bootstrap";
import TaskNumber from "../tasknumber/TaskNumber";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { BlackRadio } from "../modification/Radio";
import { useTranslation } from "react-i18next";

function ExerciseCorrect({ problem, evaluateList, index, setEvaluteList }) {
  const [taskNumber, setTaskNumber] = useState(0);
  const [notes, setNotes] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setTaskNumber(problem.points);
    setNotes(problem.notes);
    setSelectedValue(
      problem.status === "Correct" ? 0 : problem.status === "Incorrect" ? 1 : 2
    );
  }, [problem]);

  const handleTaskNumberChange = (e) => {
    if (parseInt(e.target.value) > 0) {
      setTaskNumber(parseInt(e.target.value));
    }
  };

  const handleTaskNumberInc = (newValue) => {
    if (parseInt(taskNumber) < problem.maxPoints) {
      setTaskNumber(taskNumber + newValue);
      let problems = evaluateList.problems;
      problems[index].points += newValue;
      setEvaluteList({ ...evaluateList, problems: problems });
    }
  };

  const handleTaskNumberDec = (newValue) => {
    if (parseInt(taskNumber) > 0) {
      setTaskNumber(taskNumber + newValue);
      let problems = evaluateList.problems;
      problems[index].points += newValue;
      setEvaluteList({ ...evaluateList, problems: problems });
    }
  };

  const handleChange = (newState) => {
    setSelectedValue(newState);
    let problems = evaluateList.problems;
    switch (newState) {
      case 0:
        problems[index].status = "Correct";
        break;
      case 1:
        problems[index].status = "Incorrect";
        break;
      default:
        problems[index].status = "Not Evaluated";
        break;
    }
    setEvaluteList({ ...evaluateList, problems: problems });
  };

  const handleExplanationChange = (event) => {
    let problems = evaluateList.problems;
    problems[index].notes = event.target.value;
    setEvaluteList({ ...evaluateList, problems: problems });
    setNotes(event.target.value);
  };

  return (
    <Card className={`exercise-create-card`}>
      <Container className="exercise-create-container">
        <Row className="exercise-create-row">
          <Col xs={9} md={9}>
            <Row className="exercise-create-row">
              <Col className="exercise-create-normal large-body-text exercise-border">
                <p
                  dangerouslySetInnerHTML={{
                    __html: index + 1 + ". " + problem.question,
                  }}
                ></p>
              </Col>
            </Row>
            <Row className="exercise-create-row"> 
              <Col className="exercise-create-normal">
                <p className="large-body-text">
                  {t("Exercise.solution")}:{" "}
                  {problem.solutionSteps.map((solutionSteps, index) => {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            solutionSteps.selectedSolutionStepOptionIndex !== -1
                              ? solutionSteps
                                  .offeredSolutionStepOptions[
                                    solutionSteps
                                    .selectedSolutionStepOptionIndex
                                ].presentation
                              : t("Exercise.no-submitted-solutions"),
                        }}
                      ></p>
                    );
                  })}
                  {selectedValue === 0 ? (
                    <CheckCircleOutlineOutlinedIcon
                      className="icon"
                      style={{ color: "green" }}
                    />
                  ) : selectedValue === 1 ? (
                    <CancelOutlinedIcon
                      className="icon"
                      style={{ color: "red" }}
                    />
                  ) : (
                    ""
                  )}
                </p>
                <Form.Group controlId="notes">
                  <Form.Label>
                  {t("Exercise.explanation")} <span className="normal-text">- {t("Exercise.optional")}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Exercise.explanation-placeholder")}
                    value={notes}
                    onChange={(event) => handleExplanationChange(event)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="exercise-create-row">
              <Col sm={12} onClick={() => handleChange(0)}>
                <BlackRadio
                  checked={selectedValue === 0}
                  value="0"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "0" }}
                />
                <span className="strong-text" style={{ color: "green" }}>{t("Exercise.correct")}</span>
              </Col>
              <Col sm={12} onClick={() => handleChange(1)}>
                <BlackRadio
                  checked={selectedValue === 1}
                  value="1"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "1" }}
                />
                <span className="strong-text" style={{ color: "red" }}>{t("Exercise.incorrect")}</span>
              </Col>
              <Col sm={12} onClick={() => handleChange(2)}>
                <BlackRadio
                  checked={selectedValue === 2}
                  value="2"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "2" }}
                />
                <span className="strong-text">{t("Exercise.not-evaluated")}</span>
              </Col>
              <Col className="strong-text"> {t("Exercise.score")}</Col>
            </Row>
            <Row className="exercise-create-row">
              <Col>
                <TaskNumber
                  taskNumber={taskNumber}
                  handleTaskNumberChange={handleTaskNumberChange}
                  handleTaskNumberInc={handleTaskNumberInc}
                  handleTaskNumberDec={handleTaskNumberDec}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default ExerciseCorrect;
