import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/dashboard.css";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLanguageCode } from "../../helpers";

function Login({ onNavbarChange }) {
  useEffect(() => {
    onNavbarChange("Guest");
  });

  const defaultLoginState = {
    email: "",
    password: "",
  };

  const [loginState, setLoginState] = useState(defaultLoginState);
  const language = useLanguageCode();
  const href = (window.location.pathname.includes("en-US/") || window.location.pathname.includes("hu-HU/")) ? "" : language;

  const handleLoginSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginState({ ...loginState, [name]: value });
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("Login.title",t("Global.title"))}</title>
        <meta name="description" content={t("Login.description",t("Global.description"))} />
        <meta name="keywords" content={t("Login.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
    <Container fluid>
      <Row className="dashboard-row">
        <Col sm={1} md={1}></Col>
        <Col sm={10} md={6} className="dashboard-col-middle">
          <h1>{t("Login.login-teacher")}</h1>
          <Form onSubmit={handleLoginSubmit}>
            <Form.Group controlId="email">
              <Form.Label>{t("Login.email")}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="pl. nagy.bence@freemail.hu"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>{t("Login.password")}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              bsPrefix="casual"
              className="btn primary-button primary-button-text "
            >
              <CheckCircleOutlineOutlinedIcon className="icon" />
              {t("Login.login")}
            </Button>
            <a href="/" style={{ marginLeft: "20px" }}>
            {t("Login.back")}
            </a>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col sm={12} md={4} className="dashboard-news">
          <p className="large-body-text">{t("Login.no-profile")}</p>
          <Button
            bsPrefix="casual"
            className="btn secondary-button primary-button-text"
            style={{ marginBottom: "25px" }}
          >
           {t("Login.register-now")}
          </Button>
          <p className="large-body-text">{t("Login.register-why")}</p>
          <p className="normal-body-text">
          {t("Login.register-before-link")} <a href={`${href}/registration`}>{t("Login.register-link")}</a> {t("Login.register-after-link")}
          </p>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Login;
