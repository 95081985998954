import axios from "axios";
import { checkRequirement } from "../helpers";
import {
    initProblemListSummariesState,
    resetProblemListSummariesState,
} from "../state/problemlistsummaries/actions";
import { initErrorState } from "../state/error/actions";

export function getProblemListSummaries(
    cookies,
    dispatch,
    language,
    history,
    problemTemplateListId
) {
    dispatch(resetProblemListSummariesState());
    if (checkRequirement(cookies)) {
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/problemlist/list/${problemTemplateListId}`, {
                headers: {
                    "X-Authenticated-User": cookies.guid,
                    "Accept-Language": language,
                },
            })
            .then((res) => {
                dispatch(initProblemListSummariesState(res.data));
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
