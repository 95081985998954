import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/navbar.css";
import {
    Navbar,
    Nav,
    Image,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import LogoWhite from "../../assets/Praktiko_logo_head_only.svg";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useLanguageCode} from "../../helpers";

function SignedInNavbar({
    navbarTitleTop,
    navbarTitleBottom,
}) {
    const language = useLanguageCode();
    const { t, i18n } = useTranslation();
    const href = (window.location.pathname.includes("en-US/") || window.location.pathname.includes("hu-HU/")) ? "" : language;

    return (
        <Navbar fixed="top">
            <Navbar.Brand href={`${href}`}>
                <Image src={LogoWhite} className="logo" rounded />
            </Navbar.Brand>
            <Navbar.Brand>
                <Container className="nav-container">
                    <Row className="nav-container-row">
                        <Col>
                            <span className="navbar-title-top">{navbarTitleTop}</span>
                            <br></br>
                            <span className="navbar-title-bottom">{navbarTitleBottom}</span>
                        </Col>
                    </Row>
                </Container>
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <Nav className="navbar-right">
                <Nav.Link className="new-task" href={`${href}/create`}>
                    <AddCircleOutline className="icon not-button-icon" />
                    {t("Navbar.Signed-In-Navbar.new-problem-list")}
                </Nav.Link>
                <Nav.Link href="/feature" style={{ display: "none" }}>
                    <AccountCircleOutlinedIcon className="icon not-button-icon" />
          N. Réka
        </Nav.Link>
                <Nav.Link href="/feature" style={{ display: "none" }}>
                    <ExpandMoreIcon className="icon not-button-icon" />
                </Nav.Link>
            </Nav>
        </Navbar>
    );
}

export default SignedInNavbar;
