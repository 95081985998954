import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/list.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import ExerciseCorrect from "../exercise/ExerciseCorrect";
import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";

function ExerciseDetailsSubmissions({
    problemList,
    evaluateList,
    setEvaluteList,
}) {
    const { t } = useTranslation();
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        if (
            problemList.problems.length !== 0 &&
            evaluateList.problems.length !== 0
        ) {
            setSpinner(false);
        }
    }, [problemList, evaluateList]);

    return (
        <Row className="create-row-bottom">
            <Col sm={1}></Col>
            <Col sm={10}>
                <h3>{t("Exercise-Details.Exercise-Submission-Exercises.handed-problems")}</h3>
                {spinner ? (
                    <Card className="dashboard-card">
                        <Container className="dashboard-container">
                            <Row className="dashboard-row dashboard-row-middle">
                                <Col>
                                    <Row className="dashboard-row dashboard-row-middle">
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            variant="primary"
                                            className={"mx-auto my-auto"}
                                        >
                                            <span className="sr-only">
                                            {t("Global.loading")}
                                            </span>
                                        </Spinner>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Card>
                ) : (
                    problemList.problems.map((problem, index) => (
                        <ExerciseCorrect
                            problem={problem}
                            evaluateList={evaluateList}
                            index={index}
                            setEvaluteList={setEvaluteList}
                            key={problem.guid}
                        />
                    ))
                )}
            </Col>
        </Row>
    );
}

export default ExerciseDetailsSubmissions;
