import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Feature({ onNavbarChange }) {
  useEffect(() => {
    onNavbarChange("None");
  }, [onNavbarChange]);

  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Feature.title")}</title>
        <meta name="description" content={t("Feature.description",t("Global.description"))} />
        <meta name="keywords" content={t("Feature.keywords",t("Global.keywords"))} />
        <meta http-equiv="Content-Language" content={i18n.language} />
        <html lang={i18n.language}/>
      </Helmet>
    <div className="full-div">
      <h1>{t("Feature.upcoming-feature")}</h1>
    </div>
    </>
  );
}

export default Feature;
