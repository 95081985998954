import { createStore, applyMiddleware, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { navbarStateReducer } from "./navbar/reducer";
import { problemListStateReducer } from "./problemlist/reducer";
import { templatelistStateReducer } from "./templatelist/reducer";
import { createTemplateStateReducer } from "./createtemplate/reducer";
import { randomProblemsStateReducer } from "./randomproblems/reducer";
import { problemTemplateListSummariesStateReducer } from "./problemtemplatelistsummaries/reducer";
import { problemTemplateListStateReducer } from "./problemtemplatelist/reducer";
import { problemListSummariesStateReducer } from "./problemlistsummaries/reducer";
import { errorStateReducer } from "./error/reducer";

const rootReducer = combineReducers({
    createTemplateState: createTemplateStateReducer,
    errorState: errorStateReducer,
    navbarState: navbarStateReducer,
    problemListState: problemListStateReducer,
    problemListSummariesState: problemListSummariesStateReducer,
    problemTemplateListState: problemTemplateListStateReducer,
    problemTemplateListSummariesState: problemTemplateListSummariesStateReducer,
    randomProblemsState: randomProblemsStateReducer,
    templatelistState: templatelistStateReducer,
});

/*const logger = createLogger({
  collapsed: true,
});*/

export const configureStore = () => {
    return createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk))
    );
};

export const store = configureStore();
