export const PROBLEMTEMPLATELISTSUMMARIES_STATE_INIT =
  "PROBLEMTEMPLATELISTSUMMARIES_STATE_INIT";
export const PROBLEMTEMPLATELISTSUMMARIES_STATE_RESET =
  "PROBLEMTEMPLATELISTSUMMARIES_STATE_RESET";

export const initProblemTemplateListSummariesState = (element) => ({
  type: PROBLEMTEMPLATELISTSUMMARIES_STATE_INIT,
  payload: element,
});

export const resetProblemTemplateListSummariesState = (element) => ({
  type: PROBLEMTEMPLATELISTSUMMARIES_STATE_RESET,
  payload: element,
});
