import React, { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import ProblemListCreationStep1Article from "./step1/ProblemListCreationStep1Article";
import ProblemListCreationStep2Article from "./step2/ProblemListCreationStep2Article";
import ProblemListCreationStep3Article from "./step3/ProblemListCreationStep3Article";
import ProblemListCreationStep4Article from "./step4/ProblemListCreationStep4Article";
import { Redirect, useHistory } from "react-router-dom";
import { getProblemTemplates } from "../../api/GetProblemTemplates";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTemplatelistState } from "../../state/templatelist/selectors";
import { useCookies } from "react-cookie";
import { getRandomProblemsState } from "../../state/randomproblems/selectors";
import { createProblemTemplateList } from "../../api/CreateProblemTemplateList";
import { resetTemplatelistState } from "../../state/templatelist/actions";
import { randomProblemComponentChangeStateToReset } from "../../state/randomproblems/actions";
import { useLanguageCode } from "../../helpers";
/**
 * ProblemListCreationJourneyProvider represents the main (article) area of the current page during the problem list creation progess for all four steps.
 * @param {number} createJourneyState - Indicates the step number we are at. Ranges between 0 and 4.
 * @returns The react-bootstrap container that represents the body of the current page.
 */
function ProblemListCreationJourneyProvider({
    onNavbarChange,
    createJourneyState,
    handleNavbarTitleTop,
    handleNavbarTitleBottom,
    handleCreateJourneyState,
    resetCreateJourneyState,
    setCanClickOnNextCreate,
    sendCreateProblemTemplateList,
    setSendCreateProblemTemplateList,
}) {
    const initialState = {
        id: "-",
        templateGuids: [],
        maxPoints: [],
        isPublic: true,
        isFinal: true,
        createdAt: "2000-01-01T00:00:00.000Z",
        authorUserGuid: "",
        author: "",
        title: "",
        description: "",
        tags: [],
        onePageOneProblem: true,
        allProblemsAreMandatory: true,
        timeLimit: 0,
        expiresAt: "",
        variablePointsPerProblem: true,
        automatedProblemEvaluation: true,
    };

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [cookies, setCookie] = useCookies(["guid"]);
    const [templateList, setTemplateList] = useState(initialState);
    const [subcategories, setSubcategories] = useState([]);
    const [subject, setSubject] = useState("none");
    const templatelist = useSelector(getTemplatelistState);
    const [timeLimitHour, setTimeLimitHour] = useState(0);
    const [timeLimitMinute, setTimeLimitMinute] = useState(0);
    const [expireDate, setExpireDate] = useState("");
    const [expireHour, setExpireHour] = useState(0);
    const [expireMinute, setExpireMinute] = useState(0);
    const [
        createdProblemTemplateList,
        setCreatedProblemTemplateList,
    ] = useState(false);
    const randomProblems = useSelector(getRandomProblemsState);
    const language = useLanguageCode();

    useEffect(() => {
        dispatch(resetTemplatelistState());
        dispatch(randomProblemComponentChangeStateToReset());
    }, []);

    useEffect(() => {
        onNavbarChange("Toolbar");
    }, [onNavbarChange]);

    useEffect(() => {
        resetCreateJourneyState(0);
    }, [resetCreateJourneyState]);

    useEffect(() => {
        setSendCreateProblemTemplateList(0);
    }, [setSendCreateProblemTemplateList]);

    useEffect(() => {
        switch (createJourneyState) {
            case 0:
                subcategories.length !== 0
                    ? setCanClickOnNextCreate(true)
                    : setCanClickOnNextCreate(false);
                break;
            case 1:
                templateList.templateGuids.length !== 0
                    ? setCanClickOnNextCreate(true)
                    : setCanClickOnNextCreate(false);
                break;
            default:
        }
    }, [
        subcategories,
        templateList.templateGuids,
        createJourneyState,
        setCanClickOnNextCreate,
    ]);

    useEffect(() => {

        setLanguage();

        if (
            sendCreateProblemTemplateList === 1 &&
            !createdProblemTemplateList
        ) {
            templateList.authorUserGuid = cookies.guid;

            let sortedGuids = [];
            let sortedMaxPoints = [];

            randomProblems.forEach((randomProblem) => {
                for (let i = 0; i < templateList.templateGuids.length; i++) {
                    if (
                        randomProblem.templateGuid ===
                        templateList.templateGuids[i]
                    ) {
                        sortedGuids.push(templateList.templateGuids[i]);
                        sortedMaxPoints.push(templateList.maxPoints[i]);
                    }
                }
            });

            templateList.templateGuids = sortedGuids;
            templateList.maxPoint = sortedMaxPoints;

            const hu = window.location.href.includes("hu-HU");
            const code=hu? ("hu-HU"):("en-US")
    

            createProblemTemplateList(
                cookies,
                dispatch,
                history,
                code,
                handleCreateJourneyState,
                setSendCreateProblemTemplateList,
                templateList
            );
            setCreatedProblemTemplateList(true);
        }
    }, [
        cookies,
        dispatch,
        history,
        i18n.language,
        randomProblems,
        sendCreateProblemTemplateList,
        templateList,
    ]);

    const setLanguage = () => {
        const en = window.location.href.includes("en-US");
        const hu = window.location.href.includes("hu-HU");

        if (en) {

            i18n.changeLanguage("en");
            setCookie("lang", "en", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
            
        } else if(hu) {

            i18n.changeLanguage("hu");
            setCookie("lang", "hu", {
                path: "/",
                expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 100)
                ),
                maxAge: 100000000,
                domain: window.location.hostname,
            });
            
        } 

    }

    const handleTimeLimitHourChange = (value) => {
        if (parseInt(value) >= 0 && parseInt(value) <= 999) {
            setTimeLimitHour(parseInt(value));
        } else if (parseInt(value) > 999) {
            setTimeLimitHour(999);
        } else if (value === "") {
            setTimeLimitHour("");
        }
    };

    const handleTimeLimitMinuteChange = (value) => {
        if (parseInt(value) >= 0 && parseInt(value) <= 59) {
            setTimeLimitMinute(parseInt(value));
        } else if (parseInt(value) > 59) {
            setTimeLimitMinute(59);
        } else if (value === "") {
            setTimeLimitMinute("");
        }
    };

    const handleExpireDateChange = (newTimeLimitMinute) => {
        setExpireDate(newTimeLimitMinute);
    };

    // Settings for Create1 Provider

    const removeTemplateGuids = useCallback(
        (name) => {
            let templateGuidsToRemove = [];

            templatelist.subgroups.forEach((subgroup) => {
                subgroup.subgroups.forEach((element) => {
                    if (element.filter === name) {
                        element.templateGuids.forEach((templateGuid) => {
                            templateGuidsToRemove.push(templateGuid);
                        });
                    }
                });
            });

            const newMaxPoints = [];
            const newGuids = [];

            for (let i = 0; i < templateList.templateGuids.length; i++) {
                if (
                    !templateGuidsToRemove.includes(
                        templateList.templateGuids[i]
                    )
                ) {
                    newGuids.push(templateList.templateGuids[i]);
                    newMaxPoints.push(templateList.maxPoints[i]);
                }
            }

            setTemplateList({
                ...templateList,
                maxPoints: newMaxPoints,
                templateGuids: newGuids,
            });
        },
        [templateList, templatelist.subgroups]
    );

    const handleCategorySelectChange = (name) => {
        if (subcategories.includes(name)) {
            setSubcategories(
                subcategories.filter((subject) => subject !== name)
            );
            removeTemplateGuids(name);
        } else {
            setSubcategories([...subcategories, name]);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTemplateList({ ...templateList, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        templateList[name]
            ? setTemplateList({ ...templateList, [name]: false })
            : setTemplateList({ ...templateList, [name]: true });
    };

    const handleSubjectStateChange = (subject, searchedKey) => {

        const hu = window.location.href.includes("hu-HU");
        const code=hu? ("hu-HU"):("en-US")

        setSubject(subject);

        getProblemTemplates(
            cookies,
            dispatch,
            code,
            history,
            subject,
            searchedKey
        );
    };

    const handleSearchedKeyChange = (subject, searchedKey) => {
        if (subject !== "none") {

            const hu = window.location.href.includes("hu-HU");
            const code=hu? ("hu-HU"):("en-US")

            getProblemTemplates(
                cookies,
                dispatch,
                code,
                history,
                subject,
                searchedKey
            );
        }
    };

    const handleEndOfJourney = () => {
        setTimeout(function () {
            setSendCreateProblemTemplateList(0);
            resetCreateJourneyState();
        }, 100);
        return <Redirect to="/"></Redirect>;
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        value === "true"
            ? setTemplateList({ ...templateList, [name]: true })
            : setTemplateList({ ...templateList, [name]: false });
    };

    const handleTimeLimitChange = (newTimelimit) => {
        setTemplateList({ ...templateList, timeLimit: newTimelimit });
    };

    const handleExpireDateUTCChange = (newExpireDate) => {
        setTemplateList({ ...templateList, expiresAt: newExpireDate });
    };

    const handlePointChange = useCallback(
        (templateGuid, pointNumber) => {
            const newMaxPoints = templateList.maxPoints;

            for (let i = 0; i < templateList.templateGuids.length; i++) {
                if (templateList.templateGuids[i] === templateGuid) {
                    newMaxPoints[i] = pointNumber;
                }
            }

            setTemplateList({ ...templateList, maxPoints: newMaxPoints });
        },
        [templateList]
    );

    const problemTemplateNumberChangeEventHandler = (templateGuid, taskNumber) => {
        const defaultPoint = 5;

        const newTemplateList = templateList;

        const findCurrentGuid = newTemplateList.templateGuids.findIndex(
            (tGuid) => tGuid === templateGuid
        );
        const currentMaxPoint =
            findCurrentGuid === -1
                ? defaultPoint
                : newTemplateList.maxPoints[findCurrentGuid];

        let otherGuids = [];
        let otherMaxPoints = [];

        for (let i = 0; i < newTemplateList.templateGuids.length; i++) {
            if (newTemplateList.templateGuids[i] !== templateGuid) {
                otherGuids.push(newTemplateList.templateGuids[i]);
                otherMaxPoints.push(newTemplateList.maxPoints[i]);
            }
        }

        for (let i = 0; i < taskNumber; i++) {
            otherGuids.push(templateGuid);
            otherMaxPoints.push(
                findCurrentGuid === -1 ? defaultPoint : currentMaxPoint
            );
        }

        newTemplateList.maxPoints = otherMaxPoints;
        newTemplateList.templateGuids = otherGuids;

        setTemplateList({
            ...templateList,
            maxPoints: newTemplateList.maxPoints,
            templateGuids: newTemplateList.templateGuids,
        });
    };

    const handleExpireHourChange = (newExpireHour) => {
        if (parseInt(newExpireHour) <= 23 && parseInt(newExpireHour) >= 0) {
            setExpireHour(newExpireHour);
        } else if (parseInt(newExpireHour) > 23) {
            setExpireHour(23);
        } else if (parseInt(newExpireHour) < 0) {
            setExpireHour(0);
        } else if (newExpireHour === "") {
            setExpireHour("");
        }
    };

    const handleExpireMinuteChange = (newExpireMinute) => {
        if (parseInt(newExpireMinute) <= 59 && parseInt(newExpireMinute) >= 0) {
            setExpireMinute(newExpireMinute);
        } else if (parseInt(newExpireMinute) > 59) {
            setExpireMinute(59);
        } else if (parseInt(newExpireMinute) < 0) {
            setExpireMinute(0);
        } else if (newExpireMinute === "") {
            setExpireMinute("");
        }
    };

    useEffect(() => {
        switch (createJourneyState) {
            case 0:
                handleNavbarTitleTop(
                    t("Navbar.Toolbar-Navbar.new-problem-list")
                );
                handleNavbarTitleBottom(
                    t("Navbar.Toolbar-Navbar.new-problem-list-01")
                );
                break;
            case 1:
                handleNavbarTitleTop(
                    t("Navbar.Toolbar-Navbar.new-problem-list")
                );
                handleNavbarTitleBottom(
                    t("Navbar.Toolbar-Navbar.new-problem-list-02")
                );
                break;
            case 2:
                handleNavbarTitleTop(
                    t("Navbar.Toolbar-Navbar.new-problem-list")
                );
                handleNavbarTitleBottom(
                    t("Navbar.Toolbar-Navbar.new-problem-list-03")
                );
                break;
            case 3:
                handleNavbarTitleTop(
                    t("Navbar.Toolbar-Navbar.new-problem-list")
                );
                handleNavbarTitleBottom(
                    t("Navbar.Toolbar-Navbar.new-problem-list-04")
                );
                break;
            default:
                handleNavbarTitleTop(t(""));
                handleNavbarTitleBottom("");
                break;
        }
    }, [createJourneyState, handleNavbarTitleTop, handleNavbarTitleBottom]);

    switch (createJourneyState) {
        case 0:
            return (
                <ProblemListCreationStep1Article
                    subject={subject}
                    handleSubjectStateChange={handleSubjectStateChange}
                    handleChange={handleChange}
                    templateList={templateList}
                    handleCategorySelectChange={handleCategorySelectChange}
                    subcategories={subcategories}
                    handleSearchedKeyChange={handleSearchedKeyChange}
                />
            );
        case 1:
            return (
                <ProblemListCreationStep2Article
                    subcategories={subcategories}
                    templateList={templateList}
                    subject={subject}
                    handleChange={handleChange}
                    handleRadioChange={handleRadioChange}
                    createJourneyState={createJourneyState}
                    handleCheckboxChange={handleCheckboxChange}
                    handleTimeLimitChange={handleTimeLimitChange}
                    handleExpireDateUTCChange={handleExpireDateUTCChange}
                    problemTemplateNumberChangeEventHandler={problemTemplateNumberChangeEventHandler}
                    handleCreateJourneyState={handleCreateJourneyState}
                    timeLimitHour={timeLimitHour}
                    handleTimeLimitHourChange={handleTimeLimitHourChange}
                    timeLimitMinute={timeLimitMinute}
                    handleTimeLimitMinuteChange={handleTimeLimitMinuteChange}
                    expireDate={expireDate}
                    expireHour={expireHour}
                    handleExpireHourChange={handleExpireHourChange}
                    expireMinute={expireMinute}
                    handleExpireMinuteChange={handleExpireMinuteChange}
                    handleExpireDateChange={handleExpireDateChange}
                />
            );
        case 2:
            return (
                <ProblemListCreationStep3Article
                    subcategories={subcategories}
                    handleChange={handleChange}
                    templateList={templateList}
                    handleRadioChange={handleRadioChange}
                    handlePointChange={handlePointChange}
                    createJourneyState={createJourneyState}
                    handleCheckboxChange={handleCheckboxChange}
                    handleTimeLimitChange={handleTimeLimitChange}
                    handleExpireDateUTCChange={handleExpireDateUTCChange}
                    handleNumberChange={problemTemplateNumberChangeEventHandler}
                    handleCreateJourneyState={handleCreateJourneyState}
                    timeLimitHour={timeLimitHour}
                    handleTimeLimitHourChange={handleTimeLimitHourChange}
                    timeLimitMinute={timeLimitMinute}
                    handleTimeLimitMinuteChange={handleTimeLimitMinuteChange}
                    expireDate={expireDate}
                    expireHour={expireHour}
                    handleExpireHourChange={handleExpireHourChange}
                    expireMinute={expireMinute}
                    handleExpireMinuteChange={handleExpireMinuteChange}
                    handleExpireDateChange={handleExpireDateChange}
                />
            );
        case 3:
            return <ProblemListCreationStep4Article templateList={templateList} />;
        case 4:
            return handleEndOfJourney();
        default:
            return <h1>Error</h1>;
    }
}

export default ProblemListCreationJourneyProvider;
