import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/exercise.css";
import {
    Card,
    Badge,
    Row,
    Col,
    Container,
    Form,
    Button,
    Spinner,
} from "react-bootstrap";
import TaskNumber from "../tasknumber/TaskNumber";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { RANDOMPROBLEMS_STATE_LOADING, RANDOMPROBLEMS_STATE_SUCCESS, RANDOMPROBLEMS_STATE_ERROR } from "../../state/randomproblems/actions";
import { showNotification } from '../../helpers.js'

function ProblemTemplateSetNumberComponent({
    state,
    problemTemplateGuid,
    problemTemplate,
    problemTemplateNumberChangeEventHandler,
    templateList,
}) {
    const findInTemplateListNumber = () => {
        let num = 0;
        templateList.templateGuids.forEach((tguid) => {
            if (tguid === problemTemplateGuid) {
                num++;
            }
        });
        return num;
    };

    const templateListNumber = findInTemplateListNumber();
    const [taskNumber, setTaskNumber] = useState(templateListNumber);
    const [cookies] = useCookies(["guid"]);
    const [tabState, setTabState] = useState(0);
    //const [isSpinnerVisible, setSpinnerVisibility] = useState(true);
    const { t } = useTranslation();

    const emptyProblemTemplate = {
        hash: "",
        notes: "",
        problemIndex: 0,
        question: "",
        tags: [],
        templateGuid: "",
        templateId: "",
    };

    if (problemTemplate === undefined) {
        problemTemplate = emptyProblemTemplate;
    }

    useEffect(() => {
        problemTemplateNumberChangeEventHandler(problemTemplateGuid, taskNumber);
    }, [taskNumber, problemTemplateGuid]);

    const handleTaskNumberChange = (e) => {
        if (parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 20) {
            setTaskNumber(parseInt(e.target.value));
        } else if (parseInt(e.target.value) > 20) {
            setTaskNumber(20);
        }
    };

    const handleTaskNumberInc = (newValue) => {
        if (taskNumber + newValue <= 20) {
            setTaskNumber(taskNumber + newValue);
        }
    };

    const handleTaskNumberDec = (newValue) => {
        if (taskNumber + newValue >= 0) {
            setTaskNumber(taskNumber + newValue);
        }
    };

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    console.log("ProblemTemplateSetNumberComponent state: " + state);

    const isSpinnerVisible = (state === RANDOMPROBLEMS_STATE_LOADING) || (problemTemplate.solutionSteps === undefined);
    //if (isSpinnerVisible) {
    //    setSpinnerVisibility(state === RANDOMPROBLEMS_STATE_LOADING);
    //}

    return ((state === RANDOMPROBLEMS_STATE_LOADING) || (state === RANDOMPROBLEMS_STATE_SUCCESS)) ? (
        <Card className={`exercise-create-card source-file-ProblemTemplateSetNumberComponent ${taskNumber > 0 ? "exercise-create-card-border " : ""}${isSpinnerVisible ? "active-card-spinner " : " "}`}>
            {taskNumber > 0 ? (
                <CheckCircleOutlineOutlinedIcon className="content-group-exercise-icon my-auto outer-icon" />
            ) : (
                ""
            )}
            {isSpinnerVisible ? (
                <Spinner
                    animation="border"
                    role="status"
                    variant="primary"
                    className={"mx-auto my-auto"}
                >
                    <span className="sr-only">{t("Global.loading")}</span>
                </Spinner>
            ) : (
                <Container fluid className="exercise-create-container">
                    {tabState === 0 ? (
                        <Row className="exercise-create-row">
                            <Col xs={8} sm={8} md={9} lg={9} className="exercise-container-left" onClick={() => showNotification(t("Exercise.ExerciseCreate.problem-sample-only"))}>
                                <Row className="exercise-create-row exercise-row-question">
                                    <Col className="exercise-create-normal strong-text exercise-border">
                                        <p className="exercise-question"
                                            dangerouslySetInnerHTML={{
                                                __html: problemTemplate.question,
                                            }}
                                        ></p>
                                    </Col>
                                </Row>
                                {(problemTemplate.solutionSteps === undefined ? "" : problemTemplate.solutionSteps.map((solutionStep) => (
                                    <Row className={`exercise-create-row ${solutionStep.index == problemTemplate.solutionSteps.length - 1 ? "exercise-row-solutionstep-last" : "exercise-row-solutionstep"}`}>
                                        <Col className="exercise-create-badge" sm={12}>
                                            {problemTemplate.solutionSteps.length > 1 ? (
                                                <span className="exercise-solutionstep-index">{solutionStep.index + 1}.</span>) : ("")}
                                            {solutionStep.offeredSolutionStepOptions.map((solutionStepOption) => (
                                                <Badge
                                                    className="multiple-choise-answer-view no-hover"
                                                    key={problemTemplateGuid + "-" + solutionStepOption.guid}
                                                    dangerouslySetInnerHTML={{
                                                        __html: solutionStepOption.presentation,
                                                    }}
                                                ></Badge>
                                            ))}
                                        </Col>
                                    </Row>
                                )))}
                                <Row className="exercise-create-row exercise-row-tags">
                                    <Col className="exercise-create-badge" sm={12}>
                                        {problemTemplate.tags.map((tag) => (
                                            <Badge className="pill-normal" id={tag.id} key={problemTemplateGuid + "-" + tag.id}>
                                                {tag.name}
                                            </Badge>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="exercise-container-right">
                                <Row className="exercise-create-row text-right">
                                    <Col> {t("Exercise.ExerciseCreate.exercise-number")}</Col>
                                </Row>
                                <Row className="exercise-create-row text-right">
                                    <Col>
                                        <TaskNumber
                                            taskNumber={taskNumber}
                                            handleTaskNumberChange={handleTaskNumberChange}
                                            handleTaskNumberInc={handleTaskNumberInc}
                                            handleTaskNumberDec={handleTaskNumberDec}
                                        />
                                    </Col>
                                </Row>
                                {taskNumber > 0 ? (
                                    <>
                                        <Form.Group
                                            controlId="exampleForm.ControlSelect1"
                                            style={{ display: "none" }}
                                        >
                                            <Form.Label>
                                                {t("Exercise.ExerciseCreate.answer-type")}
                                            </Form.Label>
                                            <Form.Control as="select">
                                                <option>
                                                    {t("Exercise.ExerciseCreate.multiple-choise")}
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <p
                                            style={{ color: "#118DDF", display: "none" }}
                                            onClick={() => handleTabStateChange(1)}
                                        >
                                            <SettingsIcon /> {t("Exercise.ExerciseCreate.settings")}
                                        </p>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    ) : (
                        <Row className="exercise-create-row">
                            <Col xs={9} md={9}>
                                <Row className="exercise-create-row">
                                    <Col className="exercise-create-normal strong-text">
                                        <SettingsIcon className="icon" />
                                        {t("Exercise.ExerciseCreate.settings")}
                                    </Col>
                                </Row>
                                <div className="exercise-create-settings">
                                    <Row className="exercise-create-row">
                                        <Col className="strong-text">
                                            {" "}
                                            {t("Exercise.ExerciseCreate.optional-answer-number")}
                                        </Col>
                                    </Row>
                                    <Row className="exercise-create-row">
                                        <Col>
                                            <TaskNumber
                                                taskNumber={taskNumber}
                                                handleTaskNumberChange={handleTaskNumberChange}
                                                handleTaskNumberInc={handleTaskNumberInc}
                                                handleTaskNumberDec={handleTaskNumberDec}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    bsPrefix="toolbar"
                                    className="btn primary-button primary-button-text block"
                                    onClick={() => handleTabStateChange(0)}
                                >
                                    <CheckCircleOutlineOutlinedIcon className="icon" />
                                    {t("Exercise.ExerciseCreate.save")}
                                </Button>
                                <Button
                                    bsPrefix="toolbar"
                                    className="btn secondary-button secondary-button-text block"
                                    onClick={() => handleTabStateChange(0)}
                                >
                                    {t("Exercise.ExerciseCreate.back")}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Container>
            )}
        </Card>
    ) : (state === RANDOMPROBLEMS_STATE_ERROR) ? (
        <Card className={`exercise-create-card exercise-card-error`}>
            <Container fluid className="exercise-create-container">
                <Row className="exercise-create-row">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t("Exercise.ExerciseCreate.error"),
                            }}
                        ></p>
                    </Col>
                </Row>
            </Container>
        </Card>
    ) : "";
}

export default ProblemTemplateSetNumberComponent;
