import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/group.css";
import { Card, Accordion, Button, Container, Row, Col } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List1 from "../list/List1";

function ContentGroupTopic({
  template,
  specialName,
  handleCategorySelectChange,
  subcategories,
}) {
  const [isOpen, setIsOpen] = useState(0);

  function changeIsOpenState() {
    isOpen === 0 ? setIsOpen(1) : setIsOpen(0);
  }

  return (
    <Accordion
      defaultActiveKey="-1"
      className={
        isOpen === 1
          ? "content-group-topic-accordion-open"
          : "content-group-topic-accordion"
      }
    >
      <Card className={isOpen === 1 ? "content-group-topic-open" : ""}>
        <Accordion.Toggle
          as={Button}
          onClick={changeIsOpenState}
          variant="link"
          eventKey="0"
          className="content-group-topic-button"
        >
          <Container className="content-group-topic-container">
            <Row className="content-group-topic-row">
              <Col sm={11} className={`${isOpen === 1 ? "content-group-topic-open" : ""}`}>
                <Row className="content-group-topic-row">
                  <Col className="content-group-topic-normal">
                    {specialName === undefined ? template.name : specialName}
                  </Col>
                </Row>
                <Row className="content-group-topic-row">
                  <Col className="content-group-topic-small">
                    {template.description}
                  </Col>
                </Row>
              </Col>
              <Col
                className={`${
                  isOpen === 1 ? "content-group-topic-open" : ""
                } content-group-topic-col-icon`}
              >
                <ExpandMoreIcon className="content-group-topic-icon my-auto" />
              </Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey="0"
          className={`${isOpen === 1 ? "content-group-topic-collapse" : ""}`}
        >
          <Card.Body className="content-group-topic-card-body">
            {specialName === undefined ? (
              template.subgroups.map((template) =>
                template.templateGuids.length !== 0 ? (
                  <List1
                    template={template}
                    key={template.name}
                    handleCategorySelectChange={handleCategorySelectChange}
                    subcategories={subcategories}
                  />
                ) : (
                  ""
                )
              )
            ) : (
              <List1
                template={template}
                key={template.name}
                handleCategorySelectChange={handleCategorySelectChange}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default ContentGroupTopic;
