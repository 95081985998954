import axios from "axios";
import { checkRequirement } from "../helpers";
import { initProblemTemplateListState } from "../state/problemtemplatelist/actions";
import { publishProblemTemplateList } from "./PublishProblemTemplateList";
import { initErrorState } from "../state/error/actions";

export function createProblemTemplateList(
    cookies,
    dispatch,
    history,
    language,
    handleCreateJourneyState,
    setSendCreateProblemTemplateList,
    problemList
) {
    if (checkRequirement(cookies)) {
        axios
            .post(`${process.env.REACT_APP_BASE_API_URL}/problemtemplatelist/create`, problemList, {
                headers: {
                    "X-Authenticated-User": cookies.guid,
                    "Accept-Language": language,
                },
            })
            .then((res) => {
                const isPublic = {
                    isPublic: true,
                };

                publishProblemTemplateList(
                    cookies,
                    dispatch,
                    history,
                    isPublic,
                    res.data.id
                );
                dispatch(initProblemTemplateListState(res.data));
                handleCreateJourneyState(1);
                setSendCreateProblemTemplateList(0);
            })
            .catch((err) => {
                dispatch(initErrorState(err.response));
                history.push("/error");
            });
    }
}
