import { ERROR_STATE_INIT, ERROR_STATE_RESET } from "./actions";

export const initialState = {
  status: "",
  message: "",
};

export const errorStateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === ERROR_STATE_INIT) {
    const element = payload;
    const elements = state;


    elements.status = element !== undefined ? element.status : 'Unknown error code';
    elements.message = element !== undefined ? element.request.statusText: 'Unknown error';

    return elements;
  }

  if (type === ERROR_STATE_RESET) {
    return initialState;
  }

  return state;
};
