import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import "../../styles/create.css";
import { Container } from "react-bootstrap";
import ExerciseMain from "./ExerciseMain";
import ExerciseSettings from "./ExerciseSettings";
import ExerciseExercises from "./ExerciseExercises";
import ExerciseSubmissions from "./ExerciseSubmissions";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { getProblemListSummaries } from "../../api/GetProblemListSummaries";
import { useDispatch, useSelector } from "react-redux";
import { getProblemListSummariesState } from "../../state/problemlistsummaries/selectors";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { getProblemTemplateList } from "../../api/GetProblemTemplateList";
import { getProblemTemplateListState } from "../../state/problemtemplatelist/selectors";
import { useLanguageCode } from "../../helpers";


function ExerciseDetailsProvider({ onNavbarChange, handleNavbarTitleTop, handleNavbarTitleBottom }) {
    const [cookies] = useCookies(["guid"]);
    const dispatch = useDispatch();
    const problemListSummaries = useSelector(getProblemListSummariesState);
    const problemTemplateList = useSelector(getProblemTemplateListState);
    const history = useHistory();
    const { exerciseId, tabType } = useParams();
    const { t, i18n } = useTranslation();
    const language = useLanguageCode();

    useEffect(() => {
        const lanugage = i18n.language
        if(window.location.pathname === `/${exerciseId}/submissions` ) {
            if(lanugage==="hu") window.location.assign(`/hu-HU/${exerciseId}/submissions`);
            else window.location.assign(`/en-US/${exerciseId}/submissions`);
        }
        if(window.location.pathname === `/${exerciseId}/settings` ) {
            if(lanugage==="hu") window.location.assign(`/hu-HU/${exerciseId}/settings`);
            else window.location.assign(`/en-US/${exerciseId}/settings`);
        }
    },[]);

    useEffect(() => {
        onNavbarChange("Submission");
        handleTabFromURL();
        getProblemListSummaries(cookies, dispatch, language, history, exerciseId);
        getProblemTemplateList(cookies, dispatch, language, history, exerciseId);

    }, [cookies, dispatch, exerciseId, history, i18n.language, onNavbarChange, tabType]);


    const [tabState, setTabState] = useState();

    const handleTabStateChange = (newTabState) => {
        setTabState(newTabState);
    };

    const handleTabFromURL = () => {
        if (tabType === "submissions") {
            setTabState(0);
        } else if (tabType === "questions") {
            setTabState(1);
        } else if (tabType === "settings") {
            setTabState(2);
        } else {
            setTabState(-1);
        }
    };

    handleNavbarTitleTop(t("Navbar.Submission-Navbar.submissions-title"));
    handleNavbarTitleBottom(t("Navbar.Submission-Navbar.submissions-subtitle"));

    return (
        <Container fluid>
            <ExerciseMain
                tabState={tabState}
                handleTabStateChange={handleTabStateChange}
                exerciseId={exerciseId}
                problemTemplateList={problemTemplateList}
            />
            {tabState === 0 ? (
                <ExerciseSubmissions
                    problemTemplateList={problemTemplateList}
                    problemListSummaries={problemListSummaries}
                />
            ) : tabState === 1 ? (
                true !== false ? (
                    <Redirect to="/feature"></Redirect>
                ) : (
                        <ExerciseExercises problemTemplateList={problemTemplateList} />
                    )
            ) : tabState === 2 ? (
                <ExerciseSettings problemTemplateList={problemTemplateList} />
            ) : tabState === -1 ? (
                <Redirect to="/" />
            ) : (
                                ""
                            )}
        </Container>
    );
}

export default ExerciseDetailsProvider;
